import { makeStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveIcon from "@mui/icons-material/Save";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt-BR";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/pt-br";
import { rgba } from "polished";
import { updateProfileRequest } from "../../store/modules/user/actions";
import {
    laranjaOXLTransparente,
    laranjaSecundarioTransp
} from "../../utils/styledVariables";
moment.locale("pt-br");

const useStyles = makeStyles({
  CardRoot: {
    backgroundColor: laranjaOXLTransparente,
    borderRadius: "10px",
    boxShadow: "3px 3px 6px -2px #000000",
    height: "100% !important",
  },
});

function Course() {
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);
  const [profileExperiences, setProfileExperiences] = React.useState([]);
  const [originalExperiences, setOriginalExperiences] = React.useState([]);
  const [experiencesChangeState, setExperiencesChangeState] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editable, setEditable] = React.useState(false);
  const [removable, setRemovable] = React.useState(false);
  const [addedable, setAddedable] = React.useState(false);
  const [listable, setListable] = React.useState(true);
  const menuOpen = Boolean(anchorEl);
  const [newTitle, setNewTitle] = React.useState("");
  const [newInstitution, setNewInstitution] = React.useState("");
  const [newStart, setNewStart] = React.useState(null);
  const [newEnd, setNewEnd] = React.useState(null);
  const [newUnfinished, setNewUnfinished] = React.useState(false);
  const [saveState, setSaveState] = React.useState(false);
  const [itemRemoved, setItemRemoved] = React.useState(false);
  const [deletedList, setDeletedList] = React.useState([]);
  const experienceType = "certification";

  function getTimeString(startDate, endDate) {
    if (startDate && endDate) {
      startDate = moment(startDate);
      endDate = moment(endDate);
      var duration = moment.duration(startDate.diff(endDate));
      return duration.humanize();
    }
  }

  const addExperience = () => {
    let pendingSettings;
    if (newTitle.length < 4) {
      enqueueSnackbar("Informe o nome do curso!", { variant: "error" });
      pendingSettings = true;
    }

    if (newInstitution.length < 4) {
      enqueueSnackbar("Informe a instituição!", { variant: "error" });
      pendingSettings = true;
    }

    if (!newUnfinished && newEnd === null) {
      enqueueSnackbar("Informe a data de término!", { variant: "error" });
      pendingSettings = true;
    }

    if (newStart === null) {
      enqueueSnackbar("Verifique o campo data de inicio!", {
        variant: "error",
      });
      pendingSettings = true;
    }

    if (!pendingSettings) {
      const newObject = {
        title: newTitle,
        institution: newInstitution,
        start: newStart,
        end: newEnd,
        visible: true,
        unfinished: newUnfinished,
        experience_type: experienceType,
      };
      setProfileExperiences([newObject, ...profileExperiences]);
      setSaveState(true);
    }
  };

  useEffect(() => {
    if (saveState) {
      // remove hidden elements
      const newArray = profileExperiences.filter((e) => {
        if (e.visible) {
          delete e.visible;
          return e;
        }
      });
      profile.experiences = newArray;
      dispatch(updateProfileRequest(profile));
      listHandleClose();
    }
  }, [saveState]);

  const handleChange = (event, index) => {
    const newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.unfinished = event.target.checked;
        if (item.unfinished) {
          item.end = null;
        }
      }
      return item;
    });
    setProfileExperiences(newArray);
  };

  function undoHandle() {
    setProfileExperiences(
      originalExperiences.filter((e) => (e.visible = true))
    );
    setItemRemoved(false);
    setDeletedList([]);
    setAnchorEl(null);
  }

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const menuContextHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuContextHandleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (profile.experiences && profile.experiences.length > 0) {
      const newArray = profile.experiences.map((item) => {
        item.visible = true;
        return item;
      });

      setProfileExperiences(newArray);
      setOriginalExperiences(newArray);
    }
  }, []);

  useEffect(() => {}, [deletedList]);

  const removeHandle = (index) => {
    let deletedItems = [];
    let newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.visible = false;
        deletedItems = [...deletedList, index];
      }
      return item;
    });
    setProfileExperiences(newArray);
    setItemRemoved(true);

    setTimeout(() => {
      setDeletedList(deletedItems);
    }, 500);
  };

  const changeTitle = (event, index) => {
    const newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.title = event.target.value;
      }

      return item;
    });

    setProfileExperiences(newArray);
  };

  const changeInstitution = (event, index) => {
    const newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.institution = event.target.value;
      }

      return item;
    });

    setProfileExperiences(newArray);
  };

  const handleInicio = (event, index) => {
    const newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.start = event;
      }

      return item;
    });

    setProfileExperiences(newArray);
  };

  const handleFim = (event, index) => {
    const newArray = profileExperiences.map((item, i) => {
      if (i === index) {
        item.end = event;
      }

      return item;
    });

    setProfileExperiences(newArray);
  };

  const addHandleClose = () => {
    setAddedable(true);
    setRemovable(false);
    setEditable(false);
    setListable(false);
  };

  const listHandleClose = () => {
    setAddedable(false);
    setRemovable(false);
    setEditable(false);
    setListable(true);
  };

  const editHandleClose = () => {
    setEditable(true);
    setAddedable(false);
    setRemovable(false);
    setListable(true);
  };

  const removeHandleClose = () => {
    setRemovable(true);
    setEditable(false);
    setAddedable(false);
    setListable(true);
  };

  function save() {
    if (experiencesChangeState) {
      let pendingSettings;

      profileExperiences.forEach((item) => {
        if (!item.unfinished && item.end === null) {
          enqueueSnackbar("Está faltando informar a data de término!", {
            variant: "error",
          });
          pendingSettings = true;
        }
      });

      if (!pendingSettings) {
        // remove hidden elements
        const newArray = profileExperiences.filter((e) => {
          if (e.visible) {
            delete e.visible;
            return e;
          }
        });

        profile.experiences = newArray;
        dispatch(updateProfileRequest(profile));
      }
    }
  }

  return (
    <Card classes={{ root: classes.CardRoot }} sx={{}}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="div">
            Cursos
          </Typography>
          <Box>
            <MenuItem
              key="save"
              onClick={() => {
                save();
                setAnchorEl(null);
              }}
              sx={{
                display:
                  (removable && itemRemoved) || editable
                    ? "inline-flex"
                    : "none",
              }}
            >
              <Tooltip title={"Salvar Alterações"}>
                <SaveIcon />
              </Tooltip>
            </MenuItem>
            <MenuItem
              key="undo"
              onClick={undoHandle}
              sx={{ display: itemRemoved ? "inline-flex" : "none" }}
            >
              <Tooltip title={"Desfazer"}>
                <UndoIcon />
              </Tooltip>
            </MenuItem>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={menuOpen ? "long-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={menuContextHandleClick}
            >
              <MoreHorizIcon />
            </IconButton>
          </Box>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={menuContextHandleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem
              key="listar"
              onClick={() => {
                listHandleClose();
                setAnchorEl(null);
              }}
              sx={{
                display: addedable || removable || editable ? "flex" : "none",
              }}
            >
              <ListIcon sx={{ mr: 1 }} /> Listar
            </MenuItem>
            <MenuItem
              key="adicionar"
              onClick={() => {
                addHandleClose();
                setAnchorEl(null);
              }}
              sx={{ display: !addedable ? "flex" : "none" }}
            >
              <AddIcon sx={{ mr: 1 }} /> Adicionar
            </MenuItem>
            <MenuItem
              key="editar"
              onClick={() => {
                editHandleClose();
                setExperiencesChangeState(true);
                setAnchorEl(null);
              }}
              sx={{ display: !editable ? "flex" : "none" }}
            >
              <EditIcon sx={{ mr: 1 }} /> Editar
            </MenuItem>
            <MenuItem
              key="remover"
              onClick={() => {
                removeHandleClose();
                setExperiencesChangeState(true);
                setAnchorEl(null);
              }}
              sx={{ display: !removable ? "flex" : "none" }}
            >
              <DeleteIcon sx={{ mr: 1 }} /> Remover
            </MenuItem>
          </Menu>
        </Box>
        {!addedable ? (
          profileExperiences.filter((item) => {
            if (item.experience_type == experienceType) return item;
          }).length === 0 ? (
            <Box
              sx={{
                height: "150px",
                display: listable ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchOffIcon sx={{ fontSize: "48px" }} />
                <Typography sx={{ p: 1, borderRadius: "15px" }} variant="h6">
                  Nenhum registro encontrado!
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ maxHeight: "230px", overflowY: "scroll" }}>
              {profileExperiences.map((experienceItem, index) => {
                if (experienceItem.experience_type == experienceType) {
                  return (
                    <Box
                      sx={{
                        display:
                          deletedList.indexOf(index) !== -1 ? "none" : "inline",
                      }}
                      key={"item-" + index}
                    >
                      <Fade timeout={500} in={experienceItem.visible}>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <Box
                            sx={{
                              position: "relative",
                              ":hover": {
                                borderColor: laranjaSecundarioTransp, //"#cdb7d8",
                                scale: "0.99 0.99",
                                transition: "0.1s",
                              },
                              border: `2px solid ${laranjaSecundarioTransp}`,
                              borderRadius: "5px",
                              mx: 2,
                              mt: 1.5,
                              p: 1,
                              flexGrow: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: !editable ? 1 : 0,
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ gap: 1 }}>
                                {editable ? (
                                  <Box></Box>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      display: "flex",
                                      //   border: "0.5px solid #b9b9b9",
                                      px: 1,
                                      py: 0.5,
                                      boxShadow: "3px 3px 6px -2px #000000",
                                      fontWeight: 600,
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      backgroundColor: laranjaSecundarioTransp,
                                      //"rgb(173, 137, 191, 0.5)",
                                    }}
                                  >
                                    {experienceItem.unfinished
                                      ? "Em andamento"
                                      : "Concluído"}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ flexGrow: 1 }}>
                                {editable ? (
                                  <Box
                                    sx={{ display: "flex", flexWrap: "wrap" }}
                                  >
                                    <Box sx={{ fontWeight: "bold", pr: 1 }}>
                                      Nome do curso:{" "}
                                    </Box>
                                    <input
                                      id={"title-" + index}
                                      style={{
                                        flexGrow: 1,
                                        padding: "0px 4px 0px 4px",
                                        backgroundColor: rgba(0, 0, 0, 0.1),
                                        borderRadius: "5px",
                                        border: 0,
                                      }}
                                      type="text"
                                      onClick={() => {
                                        document
                                          .getElementById("title-" + index)
                                          .select();
                                      }}
                                      onChange={(event) => {
                                        changeTitle(event, index);
                                      }}
                                      value={experienceItem.title}
                                    />
                                  </Box>
                                ) : (
                                  <Typography variant="subtitle1">
                                    {experienceItem.title}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              <Box>
                                {editable ? (
                                  <>
                                    <Box
                                      sx={{ display: "flex", flexWrap: "wrap" }}
                                    >
                                      <Box sx={{ fontWeight: "bold", pr: 1 }}>
                                        Instituição:{" "}
                                      </Box>
                                      <input
                                        id={"institution-" + index}
                                        style={{
                                          flexGrow: 1,
                                          padding: "0px 4px 0px 4px",
                                          backgroundColor: rgba(0, 0, 0, 0.1),
                                          borderRadius: "5px",
                                          border: 0,
                                        }}
                                        type="text"
                                        onClick={() => {
                                          document
                                            .getElementById(
                                              "institution-" + index
                                            )
                                            .select();
                                        }}
                                        onChange={(event) => {
                                          changeInstitution(event, index);
                                        }}
                                        value={experienceItem.institution}
                                      />
                                    </Box>
                                  </>
                                ) : (
                                  <Typography sx={{ px: 1 }} variant="body1">
                                    {experienceItem.institution}
                                  </Typography>
                                )}
                              </Box>
                              <Box>
                                {editable ? (
                                  <>
                                    <Box
                                      sx={{ mt: 2, display: "flex", gap: 1 }}
                                    >
                                      <Box>
                                        <LocalizationProvider
                                          adapterLocale={ptLocale}
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <MobileDatePicker
                                            disableFuture
                                            cancelText="Cancelar"
                                            label="Data de Inicio"
                                            inputFormat="dd/MM/yyyy"
                                            value={experienceItem.start}
                                            onChange={(event) => {
                                              handleInicio(event, index);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                color="warning"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </Box>
                                      <Box sx={{ gap: 1, display: "flex" }}>
                                        <Collapse
                                          orientation="horizontal"
                                          in={!experienceItem.unfinished}
                                        >
                                          <LocalizationProvider
                                            adapterLocale={ptLocale}
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <MobileDatePicker
                                              disableFuture
                                              cancelText="Cancelar"
                                              label="Data de Término"
                                              inputFormat="dd/MM/yyyy"
                                              value={experienceItem.end}
                                              minDate={experienceItem.start}
                                              onChange={(event) => {
                                                handleFim(event, index);
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  color="warning"
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        </Collapse>
                                      </Box>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                            checked={experienceItem.unfinished}
                                            onChange={(event) => {
                                              handleChange(event, index);
                                            }}
                                          />
                                        }
                                        label="Em andamento"
                                      />
                                    </Box>
                                  </>
                                ) : (
                                  <Box
                                    sx={{
                                      px: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Typography variant="body1">
                                      {new Date(
                                        experienceItem.start
                                      ).getFullYear()}
                                    </Typography>
                                    <Typography variant="body1">—</Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        display:
                                          !experienceItem.unfinished &&
                                          experienceItem.end !== null
                                            ? "flex"
                                            : "none",
                                      }}
                                    >
                                      {new Date(
                                        experienceItem.end
                                      ).getFullYear()}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-10px",
                                left: "calc(100% - 30px)",
                                background: laranjaOXLTransparente, //"#ede5f1",
                              }}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  display: removable ? "flex" : "none",
                                  border: "0.5px solid red",
                                  color: "red",
                                }}
                                onClick={() => {
                                  removeHandle(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Fade>
                    </Box>
                  );
                }
              })}
            </Box>
          )
        ) : (
          <Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box
                sx={{
                  position: "relative",
                  ":hover": {
                    borderColor: "cdb7d8",
                    scale: "0.99 0.99",
                    transition: "0.1s",
                  },
                  border: `2px solid ${laranjaSecundarioTransp}`,
                  borderRadius: "5px",
                  m: 2,
                  p: 1,
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Box sx={{ fontWeight: "bold", pr: 1 }}>
                        Nome do curso:{" "}
                      </Box>
                      <input
                        id={"title-new"}
                        style={{
                          flexGrow: 1,
                          padding: "0px 4px 0px 4px",
                          backgroundColor: rgba(0, 0, 0, 0.1),
                          borderRadius: "5px",
                          border: 0,
                        }}
                        type="text"
                        onChange={(event) => {
                          setNewTitle(event.target.value);
                        }}
                        value={newTitle}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Box sx={{ fontWeight: "bold", pr: 1 }}>
                        Instituição:{" "}
                      </Box>
                      <input
                        id={"institution-new"}
                        style={{
                          flexGrow: 1,
                          padding: "0px 4px 0px 4px",
                          backgroundColor: rgba(0, 0, 0, 0.1),
                          borderRadius: "5px",
                          border: 0,
                        }}
                        type="text"
                        onChange={(event) => {
                          setNewInstitution(event.target.value);
                        }}
                        value={newInstitution}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                      <Box>
                        <LocalizationProvider
                          adapterLocale={ptLocale}
                          dateAdapter={AdapterDateFns}
                        >
                          <MobileDatePicker
                            disableFuture
                            cancelText="Cancelar"
                            label="Data de Inicio"
                            inputFormat="dd/MM/yyyy"
                            value={newStart}
                            onChange={(event) => {
                              setNewStart(event);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                color="warning"
                                fullWidth
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box sx={{ gap: 1, display: "flex" }}>
                        <Collapse orientation="horizontal" in={!newUnfinished}>
                          <LocalizationProvider
                            adapterLocale={ptLocale}
                            dateAdapter={AdapterDateFns}
                          >
                            <MobileDatePicker
                              disableFuture
                              cancelText="Cancelar"
                              label="Data de Término"
                              inputFormat="dd/MM/yyyy"
                              value={newEnd}
                              minDate={newStart}
                              onChange={(event) => {
                                setNewEnd(event);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  color="warning"
                                  fullWidth
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Collapse>
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={newUnfinished}
                            onChange={(event) => {
                              setNewUnfinished(event.target.checked);
                            }}
                          />
                        }
                        label="Em andamento"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                onClick={addExperience}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Course />
    </SnackbarProvider>
  );
}
