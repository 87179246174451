import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  Button,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import React, { useRef, useState } from "react";
import logo from "../../../assets/homeTriangle/oxeTechLabLogoON.png";
import aula from "../../../assets/iconesOxeLab/aula.svg";
import api from "../../../services/api";
import FrequenciaTable from "../FrequenciaTable";
import CircularProgress from '@mui/material/CircularProgress';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "40vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  // border: '1px solid red'
};
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 255, 255, 0)",
      darker: "rgba(212, 109, 13, 1)",
    },
    secondary: {
      light: "rgba(212, 109, 13, 0.5)",
      main: "rgba(212, 109, 13, 1)",
    },
  },
});
function FormAula({ open, handleClose,aulasNumero, idTurma }) {
  const [loading, setLoading] = useState(false);
  const [matriculaSucesso, setMatriculaSucesso] = useState("");
  const [matriculaError, setMatriculaError] = useState("");
  const [titulo, setTitulo] = useState(sessionStorage.getItem("titulo") || "");
  const [assuntos, setAssuntos] = useState(() => {
    const storedAssuntos = sessionStorage.getItem("assuntos");
    return storedAssuntos ? JSON.parse(storedAssuntos) : [];
  });
  const [novoAssunto, setNovoAssunto] = useState("");
  const [data, setData] = useState(sessionStorage.getItem("data") || null);
  const [frequencia, setFrequencia] = useState([]);

  const [errorTitulo, setErrorTitulo] = useState("");
  const [errorAssuntos, setErrorAssuntos] = useState("");
  const [errorData, setErrorData] = useState("");
  const [errorFrequencia, setErrorFrequencia] = useState("");
  const today = new Date();
  const assuntoInputRef = useRef(null);
  const handleTituloChange = (e) => {
    setTitulo(e.target.value);
    sessionStorage.setItem("titulo", e.target.value);
    titulo && setErrorTitulo("");
  };

  const handleNovoAssuntoChange = (e) => {
    setNovoAssunto(e.target.value);
  };

  const adicionarAssunto = () => {
    if (novoAssunto.trim() !== "") {
      setAssuntos([...assuntos, novoAssunto]);
      sessionStorage.setItem(
        "assuntos",
        JSON.stringify([...assuntos, novoAssunto])
      );
      setNovoAssunto("");
      assuntos.length >= 0 && setErrorAssuntos("");
    }
  };

  const removerAssunto = (index) => {
    const novosAssuntos = [...assuntos];
    novosAssuntos.splice(index, 1);
    sessionStorage.setItem(
      "assuntos",
      JSON.stringify(novosAssuntos.splice(index, 1))
    );

    setAssuntos(novosAssuntos);
  };

  const handleDataChange = (newDate) => {
    setData(newDate);
    sessionStorage.setItem("data", newDate);
  };
  
  const handleSubmit = () => {
    const statusNullFrequencia = frequencia.some((aluno) => aluno.status === null);
    if (!titulo || assuntos.length <= 0 || !data || statusNullFrequencia) {
      !titulo ? setErrorTitulo("Preencha este campo.") : setErrorTitulo("");
      assuntos.length <= 0
        ? setErrorAssuntos("Preencha este campo.")
        : setErrorAssuntos("");
      !data ? setErrorData("Preencha este campo.") : setErrorData("");
      statusNullFrequencia && setMatriculaError("Você não preencheu toda a frequência.");
    }else{
      setLoading(true);
      const dataForm = {
          titulo: titulo,
          assunto: JSON.stringify(assuntos),
          data: data,
          frequencia: frequencia
      }
      
    api
      .post(`/aula/turmas/${idTurma}`, dataForm )
      .then((response) => {
        setLoading(false);
        sessionStorage.removeItem("titulo");
        sessionStorage.removeItem("assuntos");
        sessionStorage.removeItem("data");
        setMatriculaSucesso(response.data.message);
        setTimeout(() => handleClose(), [2000]);
        window.location.reload();
      })
      .catch((err) => {
        setMatriculaError(err.response.data.error);
        console.log(err.response.data.error);
        setTimeout(() => handleClose(), [2000]);
        window.location.reload();
      });
    }
    
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Modal open={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5%",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={aula} alt="aula" width="100px" />
                <h1 style={{ margin: "0 8px", fontSize: "2.3em" }}>
                  Aula {aulasNumero + 1}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "20%",
                }}
              >
                <img
                  src={logo}
                  style={{ marginRight: "5%" }}
                  className="img"
                  alt="OxetechLab"
                  width="80%"
                />
                <Tooltip title="Sair">
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </Tooltip>
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  value={titulo}
                  onChange={handleTituloChange}
                  error={Boolean(errorTitulo)}
                  helperText={errorTitulo}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Assuntos"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errorAssuntos)}
                  helperText={errorAssuntos}
                  value={novoAssunto}
                  onChange={handleNovoAssuntoChange}
                  inputRef={assuntoInputRef}
                  color="secondary"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      adicionarAssunto();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={adicionarAssunto}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginLeft: "20px",
                  display: assuntos.length > 0 ? "flex" : "none",
                }}
                // sx={{maxHeight: '50%', border: '1px solid red', overflow: 'scroll'}}
              >
                <ul>
                  {assuntos?.map((assunto, index) => (
                    <li key={index} style={{ fontSize: "1.2em" }}>
                      {assunto}
                      <IconButton
                        aria-label="Excluir"
                        size="small"
                        onClick={() => removerAssunto(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={4}>
                <h3>Data da Aula</h3>
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={data}
                    onChange={handleDataChange}
                    renderInput={(params) => (
                      <TextField
                        size="large"
                        color={errorData > 0 ? "red" : "secondary"}
                        error={Boolean(errorData)}
                        helperText={errorData}
                        fullWidth
                        {...params}
                      />
                    )}
                    minDate={today}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <h3>Registro de Frequência</h3>
                <FrequenciaTable
                  turmaId={idTurma}
                  setFrequencia={(alunos) => setFrequencia(alunos)}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "3%",
              }}
            >
              <Button
              startIcon={loading ? <CircularProgress color='inherit' size={20} /> :null}
                variant="contained"
                color="secondary"
                sx={{ width: "10%", fontSize: "1.1em" }}
                onClick={handleSubmit}
              >
                Criar
              </Button>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>

      <Snackbar
        open={matriculaSucesso.length > 0}
        autoHideDuration={6000}
        onClose={() => setMatriculaSucesso("")}
      >
        <Alert
          variant="filled"
          onClose={() => setMatriculaSucesso("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {matriculaSucesso}
        </Alert>
      </Snackbar>
      <Snackbar
        open={matriculaError.length > 0}
        autoHideDuration={6000}
        onClose={() => setMatriculaError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setMatriculaError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {matriculaError}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default FormAula;
