import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Alert, Snackbar } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import logo from "../../../assets/homeTriangle/oxeTechLabLogoON.png";
import api from "../../../services/api";
import helperTexts from "../../../utils/helperTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(212, 109, 13, 0.5)",
        darker: "rgba(212, 109, 13, 1)",
      },
    },
  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  p: 4,
};

const ConfirmarSelecao = ({ open, handleClose, selecionados, turmaId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleGerarCertificados = () => {
    setLoading(true);
    api
      .post(`turma/certificados/${turmaId}`, selecionados)
      .then((response) => {
        setSuccess("Certificados emitidos!");
        setTimeout(() => {
          window.location.reload();
        }, [1000]);
      })
      .catch((error) => {
        setError(error.response.data.error);
        setTimeout(() => {
          window.location.reload();
        }, [1000]);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "35%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Deseja gerar os certificados de {selecionados.length} alunos desta
              turma?
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, textAlign: "center" }}
            >
              Ao confirmar, um e-mail será enviado com os feedbacks aos alunos.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "5%",
              }}
            >
              <Button
                variant="contained"
                onClick={handleGerarCertificados}
                sx={{ marginRight: "5%", backgroundColor: "#D46D0D" }}
                startIcon={
                  loading && (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  )
                }
              >
                Gerar certificados
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={success.length > 0}
        autoHideDuration={7000}
        onClose={() => setSuccess("")}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccess("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default function ModalCertificados({ open, onClose, turma }) {
  const [alunos, setAlunos] = useState([]);
  const [selecionado, setSelecionado] = React.useState([]);
  const [confirmar, setConfirmar] = useState(false);

  useEffect(() => {
    if (turma) {
      const fetchAlunos = async () => {
        try {
          const response = await api.get(`matriculas/turma/${turma.id}`);
          setAlunos(response.data);
        } catch(error) {
          console.log(error);
        }
      }

      fetchAlunos();
    }
  }, [turma]);

  const columns = [
    { field: "id", headerName: "Matrícula", width: 90 },
    {
      field: "aluno",
      headerName: "Nome",
      width: 500,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 500,
      editable: true,
    },
  ];

  const rows = alunos
    ?.filter((aluno) => aluno.status === "1")
    .map((aluno) => ({
      id: aluno.id,
      aluno: aluno.aluno,
      email: aluno.email,
    }));
  const handleSelectionModelChange = (newSelection) => {
    setSelecionado(newSelection);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        components={{ Toolbar: GridToolbar }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: '10px'
              }}
            >
              <img src={logo} alt="oxetechlab" width="15%" />
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
              >
                Selecione os alunos que receberão os certificados
              </Typography>
            </div>
            {/* <h4 style={{color: 'rgb(0,0,0,0.7)'}} >Lista de aprovados por taxa de frequência na turma:</h4> */}
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                selectionModel={selecionado}
                onSelectionModelChange={handleSelectionModelChange}
                disableRowSelectionOnClick
                localeText={helperTexts.dataGridToolbarText}
              />
            </Box>
            <div
              style={{
                display: selecionado.length > 0 ? "flex" : "none",
                justifyContent: "end",
                marginTop: "2%",
              }}
            >
              <Button
                onClick={() => setConfirmar(true)}
                sx={{ background: "#D46D0D" }}
                variant="contained"
              >
                Confirmar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ConfirmarSelecao
        open={confirmar}
        handleClose={() => setConfirmar(false)}
        selecionados={selecionado}
        turmaId={turma.id}
      />
    </ThemeProvider>
  );
}
