import React from "react";
import logoOxetechOrange from '../../../assets/logo_oxeOrange.png'
import {Grid, Button} from '@mui/material';
import {ModalEmpresa} from './styles'
import dash from "../../../assets/ModalFirst/dash.svg"
import info from "../../../assets/ModalFirst/info.svg"
import visualizar from "../../../assets/ModalFirst/visualizar.svg"
import add from "../../../assets/ModalFirst/add.svg"
import { Link } from 'react-router-dom';

export default function ModalFirst (props){

    return(
        <ModalEmpresa open={props.open} onCancel={props.close} footer={false} width="100vw">
            <div className="header">
                <img src={logoOxetechOrange} alt="logoOxetechOrange" className="logo" />
                <h2>Olá! Bem-vindo(a) à plataforma OxeTech!</h2>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <h3>Aqui sua empresa pode divulgar uma vaga de emprego em dois programas: Oxedin e Oxetech Work.</h3>
                </Grid>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <div>
                        <h3 >O que é o OxeTech Work?</h3>
                        <h4>É um programa governamental que integra a capacitação de alunos com vagas de trabalho no mercado tecnológico.</h4>
                        <h4>Para que você consiga se inscrever no <Link to="/oxe-tech-work" style={{ color: '#f27420' }}><u>OxeTech Work</u></Link>, você precisará seguir o seguinte fluxo:</h4>
                        <ol >
                            <li>Acesse a aba "OxeTech Work" no menu lateral do site.</li>
                            <li>Na aba "Edital Aberto", clique no botão "Inscreva-se" para realizar sua inscrição no edital.</li>
                            <li>Aguarde a validação da sua inscrição por nossa equipe, você receberá uma notificação assim que a mesma for validada.</li>
                            <li>Após a validação da sua empresa, a opção "Publicar a Vaga no OxeTech Work" estará disponível na página de cadastro de vagas. Lembre-se que as vagas cadastradas antes da validação da empresa e/ou sem que a caixinha de seleção "Publicar Vaga no OxeTech Work" esteja marcada não estarão habilitadas no programa.</li>
                            <li>Leia atentamente o edital e todos os requisitos disponíveis na página do programa.</li>
                            <li>Atenção! Verifique se todas as condições foram cumpridas antes de cadastrar suas vagas.</li>
                        </ol>    
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <div>
                        <h3 >O que é o OxeDin?</h3>
                        <h4>O Oxedin também é um programa de divulgação de vagas de emprego e nele você pode anunciar sua vaga e encontrar profissionais qualificados.</h4>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <div>
                        <h3>Postagem de vagas</h3>
                        <h4>Você pode cadastrar uma vaga de emprego a qualquer hora, é só seguir os passos.</h4>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}  className="botoes">
                                <img src={add} alt="add" style={{marginRight: '2%'}} />
                                <h5>Botão para cadastrar uma nova vaga, ao preencher o formulário a vaga será postada no OxeDin ou no OxeTechWork, caso esteja inscrito.</h5>
                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                                <img src={visualizar} alt="visualizar" style={{marginRight: '2%'}} />
                                <h5>Botão que permite visualizar, editar ou encerrar uma vaga.</h5>
                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                                <img src={dash} alt="dash" style={{marginRight: '2%'}} />
                                <h5>Botão que leva a um painel de controle com informações importantes sobre as candidaturas em sua vaga, incluindo uma tabela com a lista de pessoas que se candidataram.</h5>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className="info" >
                <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <div>
                        <h3>Como contratar um aluno?</h3>
                        <h4>Para contratar um aluno, basta ir em Vagas, em seguida:</h4>
                        <ol >
                            <li>Acesse Vagas abertas.</li>
                            <li>Clique no botão que leva ao painel de controle da vaga: </li>
                            <img src={dash} alt="dash" style={{marginRight: '2%'}} />
                            <li>No dashboard da vaga tem a Lista de candidatos, clique no botão “Contratar” no canto direito.</li>
                            <li>Seleciona o(s) candidato(s) marcando a caixa de seleção à esquerda do nome, em seguida clique no botão “Continuar”.</li>
                            <li>Preencha a informação da data de Rescisão de Contrato que aparece abaixo. </li>
                            <li>Por fim, clique no botão "Contratar" e pronto! Um email será enviado ao candidato informando da sua contratação.</li>
                        </ol>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} sx={{display: 'flex', justifyContent:'center', marginTop: '5%'}}>
                    <h3 className='footer'>Obrigado(a) por escolher a plataforma OxeTech e boa sorte na busca por talentos!</h3>
                </Grid>
                <div style={{width: '100%', display: "flex", justifyContent: 'flex-end'}}>
                    <Button variant="contained" className="botao" onClick={props.close}>Vamos lá</Button>
                </div>
            </Grid>
            
      </ModalEmpresa>
    )


}