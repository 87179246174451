import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Options from '../../../utils/options';
import { laranjaIntermediario2 } from '../../../utils/styledVariables';
import './styles.css';

const InputLabelCustom = styled(InputLabel)(({ theme }) => ({
    color: 'white',
    '&.MuiInputLabel-root': {
      '&.Mui-focused': {
        color: `pink`,
      },
    },
  }));

const useStyles = makeStyles({
    standardSelect: {
        color: '#FFFFFF !important',
    },
  })

function FocoCarreira() {
    const [focoCarreira, setFocoCarreira] = React.useState('');
    const [editState, setEditState] = React.useState(false);
    const profile = useSelector(state => state.user.profile);

    const handleChange = (event) => {
        profile.dataChanged = true;
        profile.foco_carreira = event.target.value;
        setFocoCarreira(event.target.value);
    };

    const classes = useStyles();

    useEffect(() => {
        if (profile.foco_carreira) {
            setFocoCarreira(profile.foco_carreira);
        }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box sx={{
                mx: 2,
                mt: 1,
                maxWidth: '207px',
                width: '207px',
                borderRadius: '5px',
                bgcolor: laranjaIntermediario2,//'#9726b5',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            }}>
                <Typography noWrap
                    sx={{
                        px: 1,
                        pt: 0.5,
                        color: '#FFFFFF',
                        fontFamily: 'Roboto',
                        fontWeight: 500,
                    }} variant="body1">
                    Foco de Carreira
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        pt: 0,
                        pr: 1,
                        pb: 0.5,
                        pl: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        color: '#FFFFFF',
                        fontFamily: 'Roboto',
                        fontWeight: 300,
                    }}
                >
                    <select
                        onChange={handleChange}
                        onBlur={() => { setEditState(false); }}
                        value={focoCarreira}
                        style={{
                            display: editState ? 'flex' : 'none',
                            backgroundColor: '#8612a5',
                            border: '1px dashed pink',
                            width: '100%',
                        }}
                    >
                        {
                            Options.optionsCarrer.map((item, index) => {
                                return <option key={'item-'+index} value={item.value}>{item.value}</option>
                            })
                        }
                    </select>
                    <span
                        tabIndex="0"
                        onClick={() => { setEditState(true); }}
                        onFocus={() => { setEditState(true); }}
                        style={{
                            display: !editState ? 'flex' : 'none',
                        }}
                    >
                        {profile.foco_carreira}
                    </span>
                </Typography>
            </Box>
        </Box>
    );
}

export default FocoCarreira