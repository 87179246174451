import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import logo from "../../../assets/homeTriangle/oxeTechLabLogoON.png";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../../services/api";
import { SnackbarProvider, useSnackbar } from "notistack";


function ModalCancelMatricula({ open, handleClose, inscricaoId }) {
//   console.log( open, handleClose, inscricaoId)
  const [loading, setLoading] = useState(false);
  const [matriculaSucesso, setMatriculaSucesso] = useState("");
  const [matriculaError, setMatriculaError] = useState("");
  const [motivo, setMotivo] = useState("");
  const [errorResposta, setErrorResposta] = useState("");
  const maxLength = 200;
  const remainingChars = maxLength - motivo.length;
  const { enqueueSnackbar } = useSnackbar();
  
  const handleCancelar = () => {
    if(!motivo){
        setErrorResposta("Adicione o motivo do cancelamento.");
    }else{
        setLoading(true);
        api
        .delete(`matriculas/delete?inscricao_id=${inscricaoId}&motivo=${motivo}`)
        .then((res) => {
          setMatriculaSucesso(res.data.message);
          handleClose()
          window.location.reload();
      })
        .catch((error) => {
          enqueueSnackbar(error.response.data.error, {
            variant: "error",
          });
          // setErrorResposta(error.response.data.error);
      });
    }
};

  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5%",
        }}
      >
        <img src={logo} className="img" alt="OxetechLab" width="40%" />
        <h3>Qual o motivo do cancelamento da matrícula?</h3>
        <TextField
          multiline
          inputProps={{ maxLength: maxLength }}
          value={motivo}
          // sx={{bgcolor: errorResposta ? "transparent": "rgba(212, 109, 13, 0.1)", }}
          error={errorResposta ? true : false}
          helperText={errorResposta ? errorResposta : false}
          onChange={(e) => {
            setMotivo(e.target.value);
          }}
          size="large"
          fullWidth
        />
        <p>
          {remainingChars}/{maxLength} caracteres restantes
        </p>
        {remainingChars === 0 && (
          <p style={{ color: "red" }}>Você atingiu o limite de caracteres.</p>
        )}
        <h5 style={{ textAlign: "center" }}>
          Ao cancelar a matrícula, um e-mail será enviado para o aluno com o motivo especificado.
        </h5>
        <DialogActions>
          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            onClick={handleCancelar}
            sx={{
                backgroundColor: "#D46D0D",
                
                color: "white",
                "&:hover": { backgroundColor: "rgba(212, 109, 13, 0.45)" },
                // marginRight: '10%'
              }}
          >
            Cancelar Matrícula
          </Button>
        </DialogActions>
      </div>
      <Snackbar
        open={matriculaSucesso.length > 0}
        autoHideDuration={6000}
        onClose={() => setMatriculaSucesso("")}
      >
        <Alert
          variant="filled"
          onClose={() => setMatriculaSucesso("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {matriculaSucesso}
        </Alert>
      </Snackbar>
      <Snackbar
        open={matriculaError.length > 0}
        autoHideDuration={6000}
        onClose={() => setMatriculaError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setMatriculaError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {matriculaError}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default ModalCancelMatricula ;
