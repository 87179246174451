import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfileRequest } from '../../store/modules/user/actions';
import { laranjaOXLTransparente } from '../../utils/styledVariables';

const useStyles = makeStyles({
    CardRoot: {
      backgroundColor: laranjaOXLTransparente,
      borderRadius: '10px',
      boxShadow: '3px 3px 6px -2px #000000',
      height: '100% !important',
    },
});

function SobreMim() {
    const classes = useStyles();
    const profile = useSelector(state => state.user.profile);
    const [profileSobreMim, setProfileSobreMim] = React.useState("Fale um pouco sobre você!");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editable, setEditable] = React.useState(false);
    const [listable, setListable] = React.useState(true);
    const [state, setState] = React.useState(false);

    const menuOpen = Boolean(anchorEl);

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()

    const handleChange = (e) => {
        if (e.target.value.length <5){
            setProfileSobreMim("Fale um pouco sobre você!")
        }else{
            setProfileSobreMim(e.target.value)
        }
    }

    function save(){
        profile.sobre_mim = profileSobreMim;
        dispatch(updateProfileRequest(profile))
    }

    const menuContextHandleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const menuContextHandleClose = () => {
        setAnchorEl(null);
    };

    const listHandleClose = () => {
        setEditable(false);
        setListable(true);
    };

    const editHandleClose = () => {
        setEditable(true);
        setListable(false);
    };

    useEffect(() => {
        if (profile.sobre_mim != null)
        setProfileSobreMim(profile.sobre_mim);
    }, [])

    const ITEM_HEIGHT = 48;

    return (
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 0 }}
        >
            <Card classes={{root: classes.CardRoot}} sx={{minHeight: '229px'}}>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{fontWeight: 500}} variant="h5" component="div">
                            Sobre mim
                        </Typography>
                        <Box>
                            <MenuItem
                                key='save'
                                onClick={() => { save(); setAnchorEl(null); }}
                                sx={{ display: state ? 'inline-flex' : 'none' }}
                            >
                                <Tooltip title={'Salvar Alterações'}>
                                    <SaveIcon sx={{ mr: 1 }} />
                                </Tooltip>
                            </MenuItem>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={menuOpen ? 'long-menu' : undefined}
                                aria-expanded={menuOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={menuContextHandleClick}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={menuContextHandleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem
                                key='listar'
                                onClick={() => {listHandleClose(); setState(false); setAnchorEl(null);}}
                                sx={{display: editable ? 'flex' : 'none'}}
                            >
                                <CancelIcon sx={{mr: 1}} /> Cancelar
                            </MenuItem>
                            <MenuItem
                                key='edtiar'
                                sx={{display: listable ? 'flex' : 'none'}}
                                onClick={() => {editHandleClose(); setState(true); setAnchorEl(null);}}
                            >
                                <EditIcon sx={{mr: 1}} /> Editar
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <Slide timeout={500} direction="down" in={profileSobreMim} mountOnEnter unmountOnExit>
                            <Box>
                                <Typography sx={{ maxHeight: '150px', overflowY: 'scroll', display: (!editable && listable) ? 'flex' : 'none' }} variant="body1">
                                    {profile.sobre_mim}
                                </Typography>
                                <Box sx={{ maxHeight: '150px', overflowY: 'scroll', width: '100%', display: editable ? 'flex' : 'none' }}>
                                    <TextareaAutosize
                                        onChange={handleChange}
                                        aria-label="maximum height"
                                        minRows={3}
                                        maxLength='500'
                                        placeholder="Breve descrição sobre você"
                                        defaultValue={profile.sobre_mim != null ? profile.sobre_mim : "Fale um pouco sobre você!"}
                                        style={{ padding: '8px', width: '100%' }}
                                    />
                                </Box>
                            </Box>
                        </Slide>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <SobreMim />
        </SnackbarProvider>
    );
}