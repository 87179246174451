import React, { useEffect, useState, useMemo } from "react";

import {
  OxeCard,
  LinhaHorizontal,
  VagaName,
  Labels,
  Capa,
  Submit,
  ContainerGrid,
  GridCard,
  ModalCard,
  InfoAdd,
  ModalConfirm,
  NoVagas,
  Search,
} from "./styles";
import {
  CardContent,
  Grid,
  Avatar,
  Alert,
  Button,
  Snackbar,
  FormHelperText,
  Typography,
  CardMedia,
  CardActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import history from "../../../../services/history"
import api from "../../../../services/api"
import logo from "../../../assets/logo.svg";
import TextField from "@mui/material/TextField";
import homem from "../../../../assets/iconesOxeLab/homem.svg";
import local from "../../../../assets/iconesOxeLab/local.svg";
import noFolder from "../../../../assets/iconesOxeLab/noFolder.svg";
import noResults from "../../../../assets/iconesOxeLab/noResults.svg";
import search from "../../../../assets/iconesOxeLab/search.svg";
import capa from "../../../../assets/iconesOxeLab/capa.svg"
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { parseISO, addDays, isAfter, isSameDay} from "date-fns";
import date from "../../../assets/date.svg";
import hour from "../../../assets/hour.svg"

function VagasOxeEdu() {
  const signed = useSelector((state) => state.auth.signed);
  const profile = useSelector((state) => state.user.profile)

  const [turmas, setTurmas] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [confirm, setConfirm] = useState(false);
  const [candidatar, setCandidatar] = useState(false);
  const [anos, setAnos] = useState(-1);
  const [error, setError] = useState(false);
  const [alertExists, setAlertExists] = useState(false);
  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [busca, setBusca] = useState("");
  const [instrutores, setInstrutores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState()

  useEffect(() => {
    if (signed) {
      api
        .get(`/oxetechedu/escola/${profile.id}`)
        .then((response) => {
          const schoolId = response.data[0].Escola.id;
          setSchool(response.data[0].Escola);
  
          api
            .get(`/oxetechedu/escola/${schoolId}/turmas`)
            .then((turmasResponse) => {
              // setTurmas(turmasResponse.data);
            })
            .catch((err) => {
              console.log(err);
            });

          api.get(`/oxetechedu/turmas/turmas-disponiveis/${schoolId}`)
          .then((responseTurmasDisponiveis) => {
            const turmasDisponiveis = responseTurmasDisponiveis.data.filter(turma => 
              isAfter(new Date(turma.encerramento_inscricoes), new Date()) ||
              isSameDay(new Date(turma.encerramento_inscricoes), new Date())
            );

            api.get(`/oxetechedu/matricula/turmas/${schoolId}`) 
              .then((responseMatriculas) => {
                const turmasMatriculadas = responseMatriculas.data;

                const turmasDisponiveisFiltradas = turmasDisponiveis.filter(turmaDisponivel =>
                  !turmasMatriculadas.some(turmaMatriculada => 
                    turmaMatriculada.turma_id === turmaDisponivel.id)
                );

                setTurmas(turmasDisponiveisFiltradas);
              })
              .catch(errorMatriculas => {
                console.error("Erro ao buscar turmas matriculadas:", errorMatriculas);
              });

          })
          .catch(errorTurmasDisponiveis => {
            console.error("Erro ao buscar turmas disponíveis:", errorTurmasDisponiveis);
          });

        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .get(`/oxetechedu/escola/${profile.id}`)
        .then((response) => {
          setSchool(response.data[0].Escola);
  
          api
            .get(`/oxetechedu/escola/${response.data[0].Escola.id}/turmas`)
            .then((turmasResponse) => {
              setTurmas(turmasResponse.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [signed, profile.id]);
  

  const handleNaoLogado = () => { history.push("/select-cadastro"); };
  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setAlertSuccessful(false);
    setAlertExists(false);
    setAlertUnauthorized(false);
    setAnos(-1);
    setError(false);
    setOpen(false);
    setConfirm(false);
    setCandidatar(false);
    setId(-1);
  };

  const handleConfirmar = (e, i) => {
    setOpen(false);
    setConfirm(true);
    setId(i);
    e.stopPropagation();
  };

  const handleSubmit = async () => {
    if (signed === false) {
      handleNaoLogado();
    } else {
      setLoading(true);
      api
        .post(`/oxetechedu/turmas/inscricao/criar/${turmas[id].id}`,  {userId: profile.id,
        turma_id:turmas[id].id})
        .then((response) => {
          setAlertSuccessful(response.data.message);
          sessionStorage.removeItem(`resposta-${id}`);
          setLoading(false);
          setConfirm(false);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          setAlertUnauthorized(error.response.data.error);
          sessionStorage.removeItem(`resposta-${id}`);
          setConfirm(false);
        });
    }
  };
 
  const Confirmar = () => {
    const [respostas, setRespostas] = useState(
      []
    );
   
    const [errorRespostas, setErrorRespostas] = useState(Array(respostas.length).fill(""));
    const maxLength = 200;
  
    const handleRespostaChange = (index, resposta) => {
      const novasRespostas = [...respostas];
      novasRespostas[index] = resposta;
      setRespostas(novasRespostas);
      sessionStorage.setItem(`resposta-${id}`, JSON.stringify(novasRespostas));
  
      const novosErros = [...errorRespostas];
      novosErros[index] = "";
      setErrorRespostas(novosErros);
    };
  
   
    return (
      <ModalConfirm
        open={confirm}
        closable={false}
        centered
        onCancel={handleClose}
        footer={null}
      >
        <img src={logo} className="img" alt="OxetechLab" width="40%" />
   
        {turmas[id] && (
        <h3 style={{ textAlign: "center" }}>
          Você confirma a inscrição em {turmas[id].titulo}?
        </h3>
      )}

        <div className="butoes">
          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }validar
            onClick={handleSubmit}
            className="butao"
          >
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="butaoCancelar"
          >
            Cancelar
          </Button>
        </div>
      
      </ModalConfirm>
    );
  };

  const turmasSearch = useMemo(() => {
    const lowerSearch = busca.toLowerCase();
    const busca1 = (turma) => turma.titulo.toLowerCase().includes(lowerSearch);
    const titulo = turmas.filter(busca1);
    return titulo;
  }, [busca, turmas]);

  useEffect(() => {
    if (id !== -1) {
      api
        .get(`/instrutor/by-turma?turmaId=${turmas[id].id}`)
        .then((response) => {
          setInstrutores(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (firstLetter) {
      return firstLetter.toUpperCase();
    });
  }

  const CardExpandido = () => {
    if (id !== -1) {
      return (
        <>
          <ModalCard
            open={open}
            onCancel={handleClose}
            width={800}
            footer={null}
            closable={false}
          >
            <CardMedia
              component="img"
              alt="Capa Oxetech Lab"
              height="100"
              image={capa}
            />
            <CardContent sx={{ p: " 5% 0 " }} className="header">

              <div style={{ width: "60%" }}>
              <div>
              <img src={logo} alt="logoOxetechEdu" className="logo" style={{ width: '100px', marginLeft: '4px', height: 'auto' }} />
            </div>
                <Typography
                  className="title"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {turmasSearch[id]?.titulo}
                </Typography>
                <LinhaHorizontal />
                {/* <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.5em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {instrutores
                    ? "Prof. " + capitalizeWords(instrutores[0].name)
                    : ""}
                </Typography> */}
                <div style={{ marginTop: "5%" }}></div>
                {turmasSearch[id].dia_da_aula && <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Dia da aula:</strong>{" "}
                  {turmasSearch[id].dia_da_aula.slice(8, 10) +
                    "/" +
                    turmasSearch[id].dia_da_aula.slice(5, 7) +
                    "/" +
                    turmasSearch[id].dia_da_aula.slice(0, 4)}
                </Typography>}
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Horário da Turma:</strong>{" "}
                  {turmasSearch[id].hora_inicio +
                    "h" + " às" + turmasSearch[id].hora_fim +
                    "h" }
                </Typography>
                
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Carga Horária:</strong>{" "}
                  {turmasSearch[id]?.carga_horaria}h
                </Typography>
              </div>
              <img className="img" src={homem} alt="Icone do OxetechLab" />
            </CardContent>
            <CardContent sx={{ p: " 2% 4% " }} className="section">
              <Typography variant="h5" component="div" className="sectionName">
                Descrição do curso
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.descricao}
              </Typography>
             
              <Typography
                variant="h5"
                component="div"
                className="sectionName"
                sx={{ width: "15%", textAlign: "center" }}
              >
                Local
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {school.rua},{" "}
                {school.bairro},{" "}
                {school.numero},{" "}
                {school.complemento !== ""
                  ? school.complemento + ", "
                  : ""}{" "}
                {school.municipio},{" "}
                {school.cep} - Alagoas
              </Typography>
            </CardContent>

            <Submit>
              {turmasSearch[id].inscricao_status ? (
                <Button className={"butaoInscrito"}>Inscrito</Button>
              ) : (
                <Button
                  onClick={(e) => handleConfirmar(e, id)}
                  className={"butaoNaoInscrito"}
                >
                  Inscrever-se
                </Button>
              )}
              <div className="lastinfo">
                <h5 className="encerramento">
                  {" "}
                  {"Inscrições até " +
                    turmasSearch[id].encerramento_inscricoes.slice(8, 10) +
                    "/" +
                    turmasSearch[id].encerramento_inscricoes.slice(5, 7) +
                    "/" +
                    turmasSearch[id].encerramento_inscricoes.slice(
                      0,
                      4
                    )}{" "}
                </h5>
              </div>
            </Submit>
          </ModalCard>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (turmas.length !== 0) {
    return (
      <div style={{ width: "90vw", maxWidth: "2000px" }}>
        <Search>
          <img src={search} width="3%" alt="Busca" />
          <input
            className="input"
            placeholder="Pesquise o nome da turma.."
            type="text"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
          />
        </Search>
        {turmasSearch.length !== 0 ? (
          <GridCard container spacing={{ xs: 1, md: 3, lg: 3 }}>
            {turmasSearch.map((item, i) => {
              return (
                <ContainerGrid
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={i}
                >
                  <OxeCard
                    sx={{}}
                    onClick={() => {
                      handleOpen(i);
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="Capa Oxetech Lab"
                      height="100"
                      image={capa}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        className="title"
                        sx={{
                          fontSize:
                            item?.titulo.length > 30 ? "1.3em" : "1.6em",
                        }}
                      >
                        {item?.titulo}
                      </Typography>
                      <LinhaHorizontal />
                      <div className="info">
                      <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={hour} alt="Hora" />
                      <Typography
                        className="subtitle"
                        sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                        variant="h5"
                        component="div"
                      >
                        {item.hora_inicio + "h" + " às " + item.hora_fim + "h"}
                      </Typography>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={date} alt="Data" />
                      <Typography
                        className="subtitle"
                        sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                        variant="h5"
                        component="div"
                      >
                        {item.dia_da_aula.slice(8, 10) + "/" + item.dia_da_aula.slice(5, 7) + "/" + item.dia_da_aula.slice(0, 4)}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ width: "10px" }}></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={local} alt="Local" />
                          <Typography
                            className="subtitle"
                            sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                            variant="h5"
                            component="div"
                          >
                          {school.nome}
                          </Typography>
                        </div>
                      </div>

                      <Typography
                        className="noWrap"
                        variant="body2"
                        sx={{ minHeight: "60px", maxHeight: "60px" }}
                      >
                        {item?.descricao}
                      </Typography>
                    </CardContent>
                    <Submit>
                      {item.inscricao_status ? (
                        <Button className={"butaoInscrito"}>Inscrito</Button>
                      ) : (
                        <Button
                          onClick={(e) => handleConfirmar(e, i)}
                          className={"butaoNaoInscrito"}
                        >
                          Inscrever-se
                        </Button>
                      )}
                      <div className="lastinfo">
                        <h5 className="encerramento">
                          {" "}
                          {"Inscrições até " +
                            item.encerramento_inscricoes.slice(8, 10) +
                            "/" +
                            item.encerramento_inscricoes.slice(5, 7) +
                            "/" +
                            item.encerramento_inscricoes.slice(0, 4)}{" "}
                        </h5>
                      </div>
                    </Submit>
                  </OxeCard>
                </ContainerGrid>
              );
            })}
            <CardExpandido />
            <Confirmar />

            <Snackbar
              open={alertUnauthorized.length > 0}
              autoHideDuration={6000}
              onClose={() => setAlertUnauthorized("")}
            >
              <Alert
                variant="filled"
                onClose={() => setAlertUnauthorized("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {alertUnauthorized}
              </Alert>
            </Snackbar>
            <Snackbar
              open={alertSuccessful.length > 0}
              autoHideDuration={6000}
              onClose={() => setAlertSuccessful("")}
            >
              <Alert
                variant="filled"
                onClose={() => setAlertSuccessful("")}
                severity="success"
                sx={{ width: "100%" }}
              >
                {alertSuccessful}
              </Alert>
            </Snackbar>
          </GridCard>
        ) : (
          <NoVagas>
            <img src={noResults} width="8%" alt="Nenhuma turma encontrada" />
            <h1 style={{ marginTop: "2%" }}>
              Nenhuma turma encontrada para {busca}
            </h1>
          </NoVagas>
        )}
      </div>
    );
  } else {
    return (
      <NoVagas>
        <img
          src={noFolder}
          width="90px"
          alt="Sem turmas cadastradas no momento"
        />
        <h2 style={{ marginTop: "2%", marginBottom: '0', fontSize: "1em" }}>
          Sem turmas cadastradas no momento
        </h2>
        <p style={{ fontSize: "1em" }}>
          Acesse a aba "Minhas turmas" e cheque se há turmas que você está inscrito!
        </p>
      </NoVagas>
    );
  }
}
export default VagasOxeEdu;
