import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputMask from "react-input-mask";
import InstitutionalCategories from "../../utils/InstitutionalCategories";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import api from "../../services/api";

function getCurrentCategories() {
  const categoriesArray = JSON.parse(sessionStorage.getItem("categories"));
  let currentCategories = [];
  if (categoriesArray) {
    for (let i = 0; i < categoriesArray.length; i++) {
      currentCategories.push({
        label: categoriesArray[i],
        value: categoriesArray[i],
      });
    }
  }

  return currentCategories;
}

export default function IdEmpresaForm() {
  const [aboutCompany, setAboutCompany] = React.useState(sessionStorage.getItem("aboutcompany") || '');
  const [cnpj, setCNPJ] = React.useState(sessionStorage.getItem("cnpj") || '');
  const [errorCNPJ, setErrorCNPJ] = React.useState(null);
  const [institution, setInstitution] = React.useState(sessionStorage.getItem("institution") || '');
  const [selectedCategories, setSelectedCategories] = React.useState(
    getCurrentCategories()
  );

  const [errorInstitution, setErrorInstitution] = React.useState(null);
  const [errorCategory, setErrorCategory] = React.useState(null);
  const [errorAbout, setErrorAbout] = React.useState(null);

  const filter = createFilterOptions();

  const handleCNPJ = (event) => {
    const {
      target: { value },
    } = event;
    setCNPJ(value);
    setErrorCNPJ({ cnpj: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/);
      if (!reg.test(value) || !isValidCNPJ(value)) {
        setErrorCNPJ({ cnpj: "CNPJ inválido." });
        sessionStorage.removeItem("cnpj");
      } else {
        let clean_value = value.match(/\d+/g).join("");
        api
          .get("empresa/verify-cnpj", {
            params: {
              cnpj: clean_value,
            },
          })
          .then((resp) => {
            sessionStorage.setItem("cnpj", clean_value);
          })
          .catch((error) => {
            setErrorCNPJ({ cnpj: "CNPJ já cadastrado." });
            sessionStorage.removeItem("cnpj");
          });
      }
    } else {
      setErrorCNPJ({ cnpj: "Preencha esse campo." });
    }
  };

  const handleInstitution = (event) => {
    const {
      target: { value },
    } = event;
    setInstitution(value);
    setErrorInstitution({ institution: "" });
    if (value.length > 0) sessionStorage.setItem("institution", value);
    else {
      setErrorInstitution({ institution: "Preencha esse campo." });
      sessionStorage.removeItem("institution");
    }
  };

  const handleCategories = (e, value) => {
    setErrorCategory({ category: "" });
    if (e.type === "blur") {
      if (!sessionStorage.getItem("categories"))
        setErrorCategory({ category: "Preencha esse campo." });
    } else if (value.length === 0) {
      sessionStorage.removeItem("categories");
      setSelectedCategories([]);
      setErrorCategory({ category: "Preencha esse campo." });
    } else {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (typeof value[value.length - 1] != "object") {
        value.splice(-1, 1, {
          label: value[value.length - 1],
          value: value[value.length - 1],
        });
      }
      setSelectedCategories(value);
      sessionStorage.setItem(
        "categories",
        JSON.stringify(value.map((v) => v.value))
      );
    }
  };

  const handleCompanyChange = (event) => {
    const {
      target: { value },
    } = event;
    setAboutCompany(value);
    setErrorAbout({ about: "" });
    if (value.length > 0) sessionStorage.setItem("aboutcompany", value);
    else {
      sessionStorage.removeItem("aboutcompany");
      setErrorAbout({ about: "Preencha esse campo." });
    }
  };

  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="99.999.999/9999-99"
          value={cnpj}
          disabled={false}
          maskChar=" "
          onBlur={handleCNPJ}
          onChange={handleCNPJ}
        >
          {() => (
            <TextField
              required
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              fullWidth
              autoComplete="cnpj"
              color="warning"
              error={Boolean(errorCNPJ?.cnpj)}
              helperText={errorCNPJ?.cnpj}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="institution"
          name="institution"
          label="Nome da Instituição"
          fullWidth
          autoComplete="institution"
          color="warning"
          value={institution}
          error={Boolean(errorInstitution?.institution)}
          helperText={errorInstitution?.institution}
          onBlur={handleInstitution}
          onChange={handleInstitution}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="checkboxes-tags-demo"
          multiple
          freeSolo
          onChange={(event, value) => handleCategories(event, value)}
          // onBlur={(event, value) => handleCategories(event, value)}
          options={InstitutionalCategories}
          value={selectedCategories}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;

            const isExisting = options.some(
              (option) => inputValue === option.label
            );

            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                label: `Adicionar "${inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") return option;

            return option.value;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          // renderValue={(selected) => (
          //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          //     {selected.map((value) => (
          //       <Chip key={value} label={value} />
          //     ))}
          //   </Box>
          // )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth={true}
              required
              color="warning"
              label="Categoria da Instituição"
              placeholder="Pesquisar categoria"
              onBlur={(event, value) => handleCategories(event, value)}
              error={Boolean(errorCategory?.category)}
              helperText={errorCategory?.category}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="outlined-textarea"
          label="Sobre a instituição"
          color="warning"
          multiline
          fullWidth={true}
          rows={3}
          value={aboutCompany}
          onChange={handleCompanyChange}
          onBlur={handleCompanyChange}
          error={Boolean(errorAbout?.about)}
          helperText={errorAbout?.about}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
