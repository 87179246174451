import React from "react"
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import styled from "styled-components";
import * as palette from "../../../utils/styledVariables"

export const BotaoCadastro = styled((props) => (
  <Button
    {...props}
    startIcon={<Add />}
    sx={{  bgcolor: palette.laranjaIntermediario2,
      "&:hover": {
        bgcolor: palette.laranjaSecundario
         //palette.roxoAzulado 
    },
     
    }}
    variant="contained"
  />
))({
  height: "30px",
  margin: "2px",
  float: "right",
});