import { styled } from '@mui/material/styles'
import VerMapafig from '../../../src/assets/VerMapa.png'

import Paper from '@mui/material/Paper'

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  height: '100%',
  verticalAlign: 'middle',
  // ...theme.typography.body2, 
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  margin: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  '.titulo': {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '4.5em',
    margin: '0px',
    padding: '0px',
    '@media (max-width: 800px)': {
      fontSize: '4rem'
    },
    '@media (max-width: 320px)': {
      fontSize: '3rem'
    }
  },
  '.img': {
    '@media (max-width: 800px)': {
      width: '90%'
    }
  },
  '.subtitulo': {
    textAlign: 'left',
    color: '#FFFFFF',
    fontFamily: 'Karla',
    fontWeight: 300,
    fontStyle: 'normal',
    marginLeft: '1rem',
    '@media (max-width: 800px)': {
      fontSize: '0.5rem',
      textAlign: 'center'
    },
    '@media (min-width: 1500px)': {
      fontSize: '2em'
    }
  }
}))

export const Caixa = styled(Paper)(({ theme }) => ({
  padding: '5%',
  // height: '90vh',
  '.container': {
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      // minHeight: '0',
      height: 'auto'
    }
  }
}))

export const Mapa = styled('img')`
  &:hover {
    animation: FadeUp;
    animation-duration: 3s;
    content: url(${VerMapafig});
  }
`
