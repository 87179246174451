import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { GloboOxlIcon } from "./GloboOxlIcon";
import crownIcon from "../../assets/crown.svg";

export const LabListItem = ({ lab }) => {
  const history = useHistory();
  const { labId } = useParams();

  const handleClick = (event) => {
    event.stopPropagation();
    history.push(`/perfil-laboratorio/${lab.lab_id}`);
  };

  const CrownIcon = () => {
    return (
      <img
        src={crownIcon}
        alt="coordenador do laboratório"
        width={20}
        style={{ marginLeft: "-4px", }}
      />
    );
  };

  return (
    <ListItemButton 
      sx={{ pl: 4, }} 
      onClick={handleClick} 
      selected={labId && lab.lab_id == labId}
    >
      <ListItemIcon sx={{ minWidth: "20px" }}>
        {lab.isCoordenador ? 
          <Tooltip title="Coordenador" arrow>
            <div>
              <CrownIcon />
            </div>
          </Tooltip>
          : <GloboOxlIcon />
        }
      </ListItemIcon>
      <ListItemText primary={lab.nome} sx={{ marginLeft: "5px" }}/>
    </ListItemButton>
  );
};
