import styled from 'styled-components';
import * as palette from '../../utils/styledVariables';
import 'animate.css'

export const Container = styled.div`
  margin-top: 0.5rem;
  min-height: 100px;
  padding: 20px; 
  overflow: hidden; 

  .hearTrilha{
    display: flex;
    width: 600px;
    height: 168px;
    justify-content: center;
    flex-shrink: 0;

    color: rgba(255, 255, 255, 0.80);
    //font-family: 'Montserrat';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  }

  .cards {
    display: flex;
    justify-content: center;
    padding-top: '15px';
    height: 100vh;
    margin-right: '0';
    background: linear-gradient(180deg, #741f8a 48.1%, #340e74 89.08%,#000063 122.33%);
  }
  a {
    padding: 5px;
    min-height: 100vh;
  border: 1px solid red;


    &:hover {
      
      //border: 2px solid ${palette.profileMainColor};
     
      border-radius: 10px;
    }
  }
  .cursos {
    
    &:hover{
      animation: myAnim 1s ease 0s 1 normal forwards;
    }

    @keyframes myAnim {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}
  }
`;