import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";

const useStyles = makeStyles((theme) => ({
  customList: {
    overflow: "auto",
    maxHeight: "200px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#6B7280",
      borderRadius: "4px",
    },
  },
}));
const EditInstrutores = (props) => {
  const classesScroll = useStyles();
  const { agentes, turmaId } = props;
  const [instrutor, setInstrutor] = useState(null);
  const [instrutores, setInstrutores] = useState([]);
  const [exibirCampoAdicionar, setExibirCampoAdicionar] = useState(false);
  const [atualizarInstrutores, setAtualizarInstrutores] = useState(false);
  useEffect(() => {
    api
      .get(`instrutor/by-turma?turmaId=${turmaId}`)
      .then((response) => {
        setInstrutores(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [turmaId, atualizarInstrutores]);

  const adicionarInstrutor = () => {
    if (instrutor) {
      const instrutorExists = instrutores.some((objeto) =>
        objeto.Aluno
          ? objeto.aluno_id === instrutor.aluno_id
          : objeto.id === instrutor.aluno_id
      );
      if (!instrutorExists) {
        const data = { aluno_id: instrutor.aluno_id, turma_id: turmaId };
        api
          .post(`instrutor/`, data)
          .then((response) => {
            // setInstrutores([...instrutores, instrutor]);
            setAtualizarInstrutores(true);
            setInstrutor(null);
          })
          .catch((error) => {
            console.log(error);
          });
        setAtualizarInstrutores(false);
      } else {
        alert("Já existe um instrutor para esta turma.");
      }
    }
  };

  const excluirInstrutor = (index) => {
    console.log(instrutores[index]);
    api
      .delete(`/instrutor/${instrutores[index].instrutor_id}`)
      .then((response) => {
        // const nomesAtualizados = [...instrutores];
        // nomesAtualizados.splice(index, 1);
        // setInstrutores(nomesAtualizados);
        setAtualizarInstrutores(true);
      })
      .catch((error) => {
        console.log("Erro ao deletar instrutor");
      });
    setAtualizarInstrutores(false);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <List className={classesScroll.customList}>
          {/* {console.log("instrutores", instrutores)} */}
          {instrutores.map((nome, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={nome.Aluno ? nome.Aluno.name : nome.name}
              />
              <ListItemSecondaryAction>
                <IconButton
                  // edge="end"
                  aria-label="delete"
                  onClick={() => excluirInstrutor(index)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={12}>
        {exibirCampoAdicionar ? (
          <div style={{ display: "flex" }}>
            <Autocomplete
              //   className={classes.autocomplete}
              sx={{ width: "70%", mr: "5%" }}
              value={instrutor}
              onChange={(event, newValue) => {
                setInstrutor(newValue);
              }}
              options={agentes}
              getOptionLabel={(option) => option.Aluno.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Instrutor da turma"
                  variant="outlined"
                  required
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <strong>{option.Aluno.name} </strong>
                </li>
              )}
            />
            <IconButton
              variant="outlined"
              aria-label="fingerprint"
              color="secondary"
              onClick={adicionarInstrutor}
            >
              <AddIcon />
            </IconButton>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setExibirCampoAdicionar(true)}
            >
              Adicionar novo instrutor
            </Button>
          </div>
        )}
      </Grid>
    </>
  );
};

export default EditInstrutores;
