import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  Button,
  Tabs,
  Tab,
  ThemeProvider,
  createTheme,
  Modal,
  Typography,
} from "@mui/material";
import Sidebar from "../../components/Bolsista/SideBar/index.js";
import * as palette from "../../../utils/styledVariables.js";
import TableCard from "../../../components/DashUserAdm/TableCard.js";
import api from "../../../services/api.js";
import { useSelector } from "react-redux";
import formatDate from "../../../utils/formartDate.js";

export const Acompanhamento = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2,
      },
    },
  });

  const [width, setWidth] = useState(window.innerWidth);
  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [inscritos, setInscritos] = useState([]);
  const [loading, setLoading] = useState(true);
  const escola = useSelector((state) => state.user.escola_profile);
  const [openModal, setOpenModal] = useState(false);
  const [cursosConcluidos, setCursosConcluidos] = useState([]);
  const [alunoSelecionado, setAlunoSelecionado] = useState(null);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleOpenModal = async (alunoId) => {
    const cursos = await fetchTrilhasConcluidas(alunoId);
    setCursosConcluidos(cursos);
    setAlunoSelecionado(alunoId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCursosConcluidos([]);
    setAlunoSelecionado(null);
  };

  const formatInscritosData = async (inscritosData) => {
    const formated = await Promise.all(inscritosData.map(async (inscrito) => {
      const trilhasConcluidas = await fetchTrilhasConcluidas(inscrito.aluno_id); // Buscar trilhas concluídas
      return {
        aluno_id: inscrito.aluno_id,
        id: inscrito.id,
        status: inscrito.status,
        nome: inscrito.Aluno.name,
        numero_inscricao: inscrito.id,
        createdAt: formatDate(inscrito.createdAt),
        email: inscrito.Aluno.email,
        quantidade_faltas: inscrito.faltas,
        qtd_cursos: trilhasConcluidas.length, // Número de trilhas concluídas
      };
    }));

    setInscritos(formated);
  };

  const fetchTrilhasConcluidas = async (alunoId) => {
    try {
      const response = await api.get(`/inscricoes_trilhas_alunos/${alunoId}/trilhas-concluidas`);
      return response.data;
    } catch (error) {
      console.log("Erro ao buscar Trilhas Concluidas:", error);
      return [];
    }
  };

  const cursosHeader = [
    { field: "numero_inscricao", headerName: "Matrícula", width: 120 },
    { field: "nome", headerName: "Nome", width: 200 },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "qtd_cursos", headerName: "Total de Cursos Concluídos", width: 200 },
    {
      field: "cursos",
      headerName: "Detalhes dos Cursos",
      width: 200,
      renderCell: (params) => (
        <Button variant="contained" color="secondary" onClick={() => handleOpenModal(params.row.aluno_id)}>
          Ver Cursos
        </Button>
      ),
    },
  ];

  const table = [
    {
      name: "Acompanhamento dos alunos",
      columns: cursosHeader,
      rows: inscritos,
    },
  ];

  const [chosenTable, setChosenTable] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get(`/oxetechedu/matricula/escola/${escola.id}`).then(async (response) => {
      await formatInscritosData(response.data);
      setLoading(false);
    });
  }, [escola.id]);

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <Sidebar onMobile={onMobile} collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />
      <ThemeProvider theme={theme}>
        <Box
          component="main"
          sx={{
            backgroundColor: "#FFFFFF",
            flexGrow: 2,
            p: 1,
            width: { xs: `calc(100% - 20vh)` },
            marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
          }}
        >
          <Tabs
            centered
            value={chosenTable}
            onChange={(event, newValue) => setChosenTable(newValue)}
            sx={{ marginBottom: 1 }}
            indicatorColor="secondary"
            textColor="secondary"
          >
            {table.map((table, index) => (
              <Tab value={index} label={table.name} key={index} />
            ))}
          </Tabs>
          <TableCard props={table[chosenTable]} loading={loading} />

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                minWidth: 300,
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom>
                Cursos Concluídos
              </Typography>
              <ul>
                {cursosConcluidos.length > 0 ? (
                  cursosConcluidos.map((curso) => (
                    <li key={curso.trilha_id}>
                      <Typography variant="subtitle1">
                        {curso.TrilhaDeConhecimento.nome} {curso.TrilhaDeConhecimento.descricao}
                      </Typography>
                    </li>
                  ))
                ) : (
                  <p>Nenhum curso foi Concluído</p>
                )}
              </ul>
              <Button onClick={handleCloseModal} variant="contained" color="secondary">
                Fechar
              </Button>
            </Box>
          </Modal>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
