import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux'
import MonitorIcon from '@mui/icons-material/Monitor';
import Typography from '@mui/material/Typography';
import Options from '../../../utils/options';
import { makeStyles } from '@material-ui/styles';
import { alpha, styled } from '@mui/material/styles';
import './styles.css'

const InputLabelCustom = styled(InputLabel)(({ theme }) => ({
    color: 'white',
    '&.MuiInputLabel-root': {
      '&.Mui-focused': {
        color: `pink`,
      },
    },
  }));

const useStyles = makeStyles({
    standardSelect: {
        color: '#FFFFFF !important',
    },
  })

function OcupacaoSelect() {
    const [ocupacao, setOcupacao] = React.useState('');
    const [editState, setEditState] = React.useState(false);
    const [openState, setOpenState] = React.useState(false);
    const profile = useSelector(state => state.user.profile);

    const handleChange = (event) => {
        profile.dataChanged = true;
        profile.ocupacao = event.target.value;
        setOcupacao(event.target.value);
    };

    const classes = useStyles();

    useEffect(() => {
        setOcupacao(profile.ocupacao);
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    display: !editState ? 'flex' : 'none'
                }}
                onClick={() => {setEditState(true);}}
                onFocus={() => {setEditState(true);}}
            >
                <MonitorIcon
                    sx={{
                        color: '#FFFFFF',
                    }}
                />
                <Typography
                    tabIndex="0"
                    noWrap
                    variant="body2"
                    sx={{
                        p: 0.5,
                        color: '#FFFFFF',
                        fontFamily: 'Roboto',
                    }}
                >
                    {ocupacao}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: editState ? 'flex' : 'none',
                    py: 1,
                    width: 210,
                }}
            >
                <FormControl
                    fullWidth
                    variant="standard"
                    sx={{border: '1px dashed pink'}}
                >
                    <InputLabelCustom
                        id="demo-simple-select-label"
                    >
                        Ocupação:
                    </InputLabelCustom>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ocupacao}
                        defaultValue={ocupacao}
                        label="Ocupação:"
                        onOpen={() => setOpenState(true)}
                        onClose={() => setOpenState(false)}
                        open={openState}
                        onChange={handleChange}
                        onBlur={() => {
                            setEditState(false);
                        }}
                    >
                        {
                            Options.ocupation.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={item.value}>{item.value}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
}

export default OcupacaoSelect