import {
  Grid,
  TextField,
} from "@mui/material";
import InputMask from "react-input-mask";
import cep from "cep-promise";
import React from "react";

export default function AddressForm() {
  const [numcep, setCep] = React.useState(sessionStorage.getItem("cep") || '');
  const [numero, setNumero] = React.useState(sessionStorage.getItem("numero") || '');
  const [telefone, setTelefone] = React.useState(
    sessionStorage.getItem("telefone")
    || '');

  const [errorCep, setErrorCep] = React.useState(null);
  const [UseEndereco, setUseEndereco] = React.useState({
    rua: sessionStorage.getItem("rua") || '',
    bairro: sessionStorage.getItem("bairro") || '',
    municipio: sessionStorage.getItem("municipio") || '',
    estado: sessionStorage.getItem("estado") || '',
  });

  const handleNumero = (event) => {
    const {
      target: { value },
    } = event;
    setNumero(value);
    if (value.length > 0) sessionStorage.setItem("numero", value);
    else {
      sessionStorage.removeItem("numero");
    }
  };

  const handleTelefone = (event) => {
    const {
      target: { value },
    } = event;
    setTelefone(value);
    if (value.length > 0) sessionStorage.setItem("telefone", value);
    else {
      sessionStorage.removeItem("telefone");
    }
  };

  const handleAddressField = (event, field) => {
    const {
      target: { value },
    } = event;

    const currentAddress = { ...UseEndereco };
    currentAddress[field] = value;
    setUseEndereco(currentAddress);

    if (value.length > 0) sessionStorage.setItem(field, value);
    else {
      sessionStorage.removeItem(field);
    }
  };

  const handleCep = (event) => {
    const {
      target: { value },
    } = event;
    setCep(value);
    setErrorCep({ numcep: "" });

    let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(value);
    if (reg) {
      cep(value)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado,
          } = response;

          setUseEndereco({
            rua,
            bairro,
            municipio,
            cep,
            estado,
          });
          sessionStorage.setItem("cep", cep);
          sessionStorage.setItem("estado", estado);
          sessionStorage.setItem("municipio", municipio);
          sessionStorage.setItem("bairro", bairro);
          sessionStorage.setItem("rua", rua);
        })
        .catch((error) => {
          setErrorCep({ numcep: "Não conseguimos resgatar o seu CEP." });
          setUseEndereco({
            rua: "",
            bairro: "",
            municipio: "",
            cep: "",
            estado: "",
          });
          sessionStorage.removeItem("cep");
          sessionStorage.removeItem("estado");
          sessionStorage.removeItem("municipio");
          sessionStorage.removeItem("bairro");
          sessionStorage.removeItem("rua");
        });
    } else {
      setErrorCep({ numcep: "CEP inválido." });
      sessionStorage.removeItem("cep");
      sessionStorage.removeItem("estado");
      sessionStorage.removeItem("municipio");
      sessionStorage.removeItem("bairro");
      sessionStorage.removeItem("rua");
    }
  };

  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="99.999-999"
          value={numcep}
          disabled={false}
          maskChar=" "
          onBlur={handleCep}
          onChange={handleCep}
        >
          {() => (
            <TextField
              color="secondary"
              id="cep"
              label="CEP"
              name="cep"
              autoComplete="cep"
              error={Boolean(errorCep?.numcep)}
              helperText={errorCep?.numcep}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="rua"
          name="rua"
          label="Rua"
          fullWidth
          autoComplete="address"
          color="secondary"
          value={UseEndereco.rua}
          onChange={e => handleAddressField(e, 'rua')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="numero"
          name="numero"
          label="Número"
          fullWidth
          autoComplete="number"
          color="secondary"
          value={numero}
          onChange={handleNumero}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="bairro"
          name="bairro"
          label="Bairro"
          fullWidth
          autoComplete="neighborhood"
          color="secondary"
          value={UseEndereco.bairro}
          onChange={e => handleAddressField(e, 'bairro')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="municipio"
          name="municipio"
          label="Município"
          fullWidth
          autoComplete="city"
          color="secondary"
          value={UseEndereco.municipio}
          onChange={e => handleAddressField(e, 'municipio')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="(99) 9 9999 9999"
          value={telefone}
          disabled={false}
          maskChar=" "
          onChange={handleTelefone}
          onBlur={handleTelefone}
        >
          {() => (
            <TextField
              required
              id="telefone"
              name="telefone"
              label="Telefone"
              fullWidth
              autoComplete="phone"
              color="secondary"
              value={telefone}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputMask>
      </Grid>
    </Grid>
  );
}
