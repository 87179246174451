import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Link } from 'react-router-dom'

import { Item, Caixa } from './styles'

import logo_oxeTechBlue from '../../assets/logo_oxeTechBlue.png'

import fapealLogo from '../../../src/assets/SobreNos/fapeal.svg'
import laccanLogo from '../../../src/assets/SobreNos/laccan.svg'
import sectiLogo from '../../../src/assets/SobreNos/secti.svg'
import ufalLogo from '../../../src/assets/SobreNos/ufal.svg'

import './styles.css'

export default function BasicGrid() {
  return (
    <Caixa
      style={{
        background: '#D9D9D9'
        // marginTop: '3px'
      }}
      //   sx={{ flexGrow: 1 }}
    >
      <Grid
        className="content"
        container
        // direction="row"
        // alignItems="center"
      >
        <img
          src={logo_oxeTechBlue}
          alt="Logo Azul do Oxetech"
          width="10%"
          style={{ marginTop: '2%', marginBottom: '2%' }}
        />

        <Link
          to="/politicas-privacidade"
          style={{
            color: 'black',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '1.5em'
          }}
        >
          Política de Privacidade
        </Link>
        <h3
          style={{
            color: 'black',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '1.5em',
            fontStyle: 'normal',
            margin: '0px',
            // padding: '0px'
          }}
        >
          E-mail: oxetech@secti.al.gov.br
        </h3>
        <div>
          <a
            style={{ margin: '10px' }}
            href="https://www.youtube.com/user/Sectialagoas"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="30"
              height="28"
              viewBox="0 0 30 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 17.5L18.9875 14L12.5 10.5V17.5ZM26.95 8.365C27.1125 8.91333 27.225 9.64833 27.3 10.5817C27.3875 11.515 27.425 12.32 27.425 13.02L27.5 14C27.5 16.555 27.3 18.4333 26.95 19.635C26.6375 20.685 25.9125 21.3617 24.7875 21.6533C24.2 21.805 23.125 21.91 21.475 21.98C19.85 22.0617 18.3625 22.0967 16.9875 22.0967L15 22.1667C9.7625 22.1667 6.5 21.98 5.2125 21.6533C4.0875 21.3617 3.3625 20.685 3.05 19.635C2.8875 19.0867 2.775 18.3517 2.7 17.4183C2.6125 16.485 2.575 15.68 2.575 14.98L2.5 14C2.5 11.445 2.7 9.56667 3.05 8.365C3.3625 7.315 4.0875 6.63833 5.2125 6.34667C5.8 6.195 6.875 6.09 8.525 6.02C10.15 5.93833 11.6375 5.90333 13.0125 5.90333L15 5.83333C20.2375 5.83333 23.5 6.02 24.7875 6.34667C25.9125 6.63833 26.6375 7.315 26.95 8.365Z"
                fill="black"
              />
            </svg>
          </a>
          <a
            style={{ margin: '10px' }}
            href="https://pt-br.facebook.com/secti.alagoas"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="30"
              height="28"
              viewBox="0 0 30 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0C6.74999 0 0 6.31125 0 14.0843C0 21.1125 5.48999 26.9458 12.66 28V18.1606H8.84999V14.0843H12.66V10.9779C12.66 7.4498 14.895 5.51004 18.33 5.51004C19.965 5.51004 21.675 5.77711 21.675 5.77711V9.249H19.785C17.925 9.249 17.34 10.3313 17.34 11.4418V14.0843H21.51L20.835 18.1606H17.34V28C20.8746 27.4769 24.0933 25.7869 26.4149 23.235C28.7364 20.6832 30.008 17.4377 30 14.0843C30 6.31125 23.25 0 15 0Z"
                fill="black"
              />
            </svg>
          </a>
          <a
            style={{ margin: '10px' }}
            href="https://www.instagram.com/sectialagoas/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.54 0H18.46C22.62 0 26 3.51 26 7.83V19.17C26 21.2466 25.2056 23.2382 23.7916 24.7066C22.3776 26.1751 20.4597 27 18.46 27H7.54C3.38 27 0 23.49 0 19.17V7.83C0 5.75336 0.794391 3.76176 2.20842 2.29335C3.62244 0.824944 5.54027 0 7.54 0ZM7.28 2.7C6.03879 2.7 4.84841 3.21203 3.97074 4.12346C3.09307 5.03489 2.6 6.27105 2.6 7.56V19.44C2.6 22.1265 4.693 24.3 7.28 24.3H18.72C19.9612 24.3 21.1516 23.788 22.0293 22.8765C22.9069 21.9651 23.4 20.729 23.4 19.44V7.56C23.4 4.8735 21.307 2.7 18.72 2.7H7.28ZM19.825 4.725C20.256 4.725 20.6693 4.90279 20.974 5.21926C21.2788 5.53573 21.45 5.96495 21.45 6.4125C21.45 6.86005 21.2788 7.28928 20.974 7.60574C20.6693 7.92221 20.256 8.1 19.825 8.1C19.394 8.1 18.9807 7.92221 18.6759 7.60574C18.3712 7.28928 18.2 6.86005 18.2 6.4125C18.2 5.96495 18.3712 5.53573 18.6759 5.21926C18.9807 4.90279 19.394 4.725 19.825 4.725ZM13 6.75C14.7239 6.75 16.3772 7.46116 17.5962 8.72703C18.8152 9.9929 19.5 11.7098 19.5 13.5C19.5 15.2902 18.8152 17.0071 17.5962 18.273C16.3772 19.5388 14.7239 20.25 13 20.25C11.2761 20.25 9.62279 19.5388 8.40381 18.273C7.18482 17.0071 6.5 15.2902 6.5 13.5C6.5 11.7098 7.18482 9.9929 8.40381 8.72703C9.62279 7.46116 11.2761 6.75 13 6.75ZM13 9.45C11.9657 9.45 10.9737 9.87669 10.2423 10.6362C9.51089 11.3957 9.1 12.4259 9.1 13.5C9.1 14.5741 9.51089 15.6043 10.2423 16.3638C10.9737 17.1233 11.9657 17.55 13 17.55C14.0343 17.55 15.0263 17.1233 15.7577 16.3638C16.4891 15.6043 16.9 14.5741 16.9 13.5C16.9 12.4259 16.4891 11.3957 15.7577 10.6362C15.0263 9.87669 14.0343 9.45 13 9.45Z"
                fill="black"
              />
            </svg>
          </a>
        </div>
      </Grid>
    </Caixa>
  )
}
