import React, {useState} from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Snackbar,Alert
} from "@mui/material";
import logo from "../../../assets/homeTriangle/oxeTechLabLogoON.png";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../../services/api";
function ModalNaoSelecionaos({ open, handleClose, idTurma }) {
  // console.log(selecionados)
  const [loading, setLoading] = useState(false);
  const [naoSelecionadosSucesso, setNaoSelecionadosSucesso] = useState('');
  const [naoSelecionadosError, setNaoSelecionadosError] = useState('');
  const handleNaoSelecionados = () => {
    setLoading(true);
    api.get(`/inscricoes-oxetechlab/email-nao-selecionados/turma/${idTurma}`).then(response => {
        setLoading(false);
        setNaoSelecionadosSucesso(response.data.message)        
        setTimeout(() => 
            handleClose()
            ,[2000])
            window.location.reload()
    }).catch(err =>{
        setNaoSelecionadosError(err.response.data.error); 
        console.log(err.response.data)
    })
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5%",
        }}
      >
        <img src={logo} alt="logoOxetechLab" width="30%" />
        <h3 style={{ textAlign: "center", fontSize: "1.3em" }}>
          Enviar e-mail para os inscritos não selecionados?
        </h3>
        <DialogActions>
          <Button
            onClick={handleNaoSelecionados}
            startIcon={
                loading ? <CircularProgress color="inherit" size={20} /> : null
              }
            variant="contained"
            sx={{
              backgroundColor: "#D46D0D",
              
              color: "white",
              "&:hover": { backgroundColor: "rgba(212, 109, 13, 0.45)" },
              marginRight: '10%'
            }}
          >
            Confirmar
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
                borderColor:'#D46D0D',
              color: "#D46D0D",
              "&:hover": { borderColor: "rgba(212, 109, 13, 0.45)" },
            }}
          >
            Fechar
          </Button>
        </DialogActions>
      </div>
      <Snackbar
              open={naoSelecionadosSucesso.length > 0}
              autoHideDuration={6000}
              onClose={() => setNaoSelecionadosSucesso("")}
            >
              <Alert
                variant="filled"
                onClose={() => setNaoSelecionadosSucesso("")}
                severity="success"
                sx={{ width: "100%" }}
              >
                {naoSelecionadosSucesso}
              </Alert>
            </Snackbar>
            <Snackbar
              open={naoSelecionadosError.length > 0}
              autoHideDuration={6000}
              onClose={() => setNaoSelecionadosError("")}
            >
              <Alert
                variant="filled"
                onClose={() => setNaoSelecionadosError("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {naoSelecionadosError}
              </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalNaoSelecionaos;
