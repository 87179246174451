import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Container";
import MainHeader from "../../components/MainHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import * as palette from "../../utils/styledVariables";

const WhiteBox = styled(Box)`
  width: 30rem;
  height: 21rem;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 90px;
  bottom: 0px;
  margin: auto auto auto auto;
  border-radius: 0.5rem;
  color: #fff;
  overflow: hidden;
  text-align: center;
  background: rgba(255, 255, 255, 89%);
  animation: fadeIn;
  animation-duration: 1s;

  @media (max-width: 700px) {
    width: 38rem;
    height: 45rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#ffffff"),
  backgroundColor: palette.laranjaSecundario,
  "&:hover": {
  backgroundColor: palette.laranjaIntermediario2
},
  // backgroundColor: "#ffffff",
  // "&:hover": {
  //   backgroundColor: "#dedede",
  // },
}));

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    background: palette.gradienteLaranja180,
     // "linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)",
    height: "100vh",
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    height: "100vh",
    minWidth: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "auto",
  },
}));

export default function Help() {
  const classes = UseStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <MainHeader />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        <WhiteBox>
            {" "}
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{color: palette.laranjaIntermediario2}}
            >
              Como podemos te ajudar?
            </Typography>
            <Stack spacing={3} sx={{ mt: 4 }}>
              <Link to="/forgot">
                <ColorButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  size="large"
                >
                  Esqueci minha senha
                </ColorButton>
              </Link>
              <Link to="/recuperar-acesso">
                <ColorButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  size="large"
                >
                  Quero alterar o email cadastrado
                </ColorButton>
              </Link>
              {/* <Link to="/deletar-conta">
                <ColorButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  size="large"
                >
                  Quero deletar minha conta
                </ColorButton>
              </Link> */}
            </Stack>
        </WhiteBox>
      </div>
    </React.Fragment>
  );
}
