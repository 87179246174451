import React from "react";
import { Grid, TextField } from "@mui/material";

export default function SocialNetworkForm() {
  const [facebook, setFacebook] = React.useState(
    sessionStorage.getItem("facebook") || ''
  );
  const [instagram, setInstagram] = React.useState(
    sessionStorage.getItem("instagram") || ''
  );
  const [linkedin, setLinkedin] = React.useState(
    sessionStorage.getItem("linkedin") || ''
  );

  const handleFacebook = (event) => {
    const {
      target: { value },
    } = event;
    setFacebook(value);
    sessionStorage.setItem("facebook", value);
  };

  const handleInstagram = (event) => {
    const {
      target: { value },
    } = event;
    setInstagram(value);
    sessionStorage.setItem("instagram", value);
  };

  const handleLinkedin = (event) => {
    const {
      target: { value },
    } = event;
    setLinkedin(value);
    sessionStorage.setItem("linkedin", value);
  };

  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} sm={12}>
        <TextField
          id="facebook"
          name="facebook"
          label="Facebook da Empresa"
          fullWidth
          autoComplete="social"
          color="warning"
          value={facebook}
          onBlur={handleFacebook}
          onChange={handleFacebook}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          id="instagram"
          name="instagram"
          label="Instagram da Empresa"
          fullWidth
          autoComplete="social"
          color="warning"
          value={instagram}
          onBlur={handleInstagram}
          onChange={handleInstagram}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          id="linkedin"
          name="linkedin"
          label="LinkedIn da Empresa"
          fullWidth
          autoComplete="social"
          color="warning"
          value={linkedin}
          onBlur={handleLinkedin}
          onChange={handleLinkedin}
        />
      </Grid>
    </Grid>
  );
}
