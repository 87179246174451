import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export default function CancelButton({ onClick, defaultText, cancelText }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant="contained"
      startIcon={isHovered ? <DeleteForeverIcon/> : <CheckIcon />}
      onClick={onClick}
      sx={{
        backgroundColor: "green",
        color: "white",
        "&:hover": {
          backgroundColor: "red",
        },
      }}
    >
      {isHovered ? cancelText : defaultText}
    </Button>
  );
}
