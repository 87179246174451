import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import Paper from '@mui/material/Paper'

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  verticalAlign: 'middle',
  ...theme.typography.body2,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  width: '90%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.titulo': {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '4.5em',
    '@media (max-width: 800px)': {
      marginTop: '2%'
    }
  },
  '.subtitulos': {
    // width: '95%',
    textAlign: 'justify',
    color: '#FFFFFF',
    fontFamily: 'Karla',
    
    '@media (max-width: 800px)': {
      textAlign: 'center',
      fontSize: '2rem'
    },
    '@media (min-width: 1500px)': {
      fontSize: '2em'
    }
  }
}))

export const Caixa = styled(Box)`
  padding-top: 5%;
  .caixa {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .img {
    width: 70%;
    @media (max-width: 800px) {
      width: 40%;
    }
  }

  .logos{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;

    .imgs{
      width: 80%;
      @media (max-width: 800px) {
        width: 60%;
      }
    }

  }
`

export const Texto = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 90%;
  p {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Karla';
    text-align: justify;
  }

  @media (max-width: 800px) {
   justify-content: center;
   align-content: center;
   width: 90%;
   padding-left: 2%;
    
}

`

