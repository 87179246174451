import styled from "styled-components";
import * as palette from "../../utils/styledVariables";
export const Container = styled.div`
  height: 10vh;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 3.5vh;
  line-height: 3.5vh;
  color: #f8f8f8;
  padding: 3% 10px 3% 10px;

  display: flex;
  align-items: center;
  text-align: center;

  background: ${palette.laranjaIntermediario2};
  border-radius: 1vh 1vh 0vh 0vh;
`;

  // background: radial-gradient(
  //   292.41% 335.33% at 41.6% 50%,
  //   #741f8a 2.22%,
  //   #1609b3 100%
  // );
export const Subtitles = styled.div`
  font-family: "Roboto";
  font-style: bold;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 2.5vh;
  margin: 20px 5px 5px 10px;
`;
