import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import 'animate.css'

export const Lab = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45rem;
  /* height: 60vh; */
  padding-top: 7%;
  background: linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);
  border-color: rgba(0, 0, 0, 0.49);
  /* @media (min-width: 700px) {
    height: 75vh;
  }
  @media (max-height: 900px) {
    height: 80vh;
  } */
`
export const Din = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 60vh; */
  height: 45rem;

  padding-top: 7%;

  background: linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);
  border-color: rgba(0, 0, 0, 0.49);
  /* @media (min-width: 700px) {
    height: 75vh;
  } */
`
export const Work = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45rem;
  /* height: 60vh; */

  padding-top: 7%;
  background: linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);
  border-color: rgba(0, 0, 0, 0.49);
  /* @media (min-width: 700px) {
    height: 75vh;
  }
  @media (max-height: 900px) {
    height: 80vh;
  } */
`

export const Title = styled(Box)`
  width: 80vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh;
  font-family: 'Poppins', sans-serif;
  .titulo {
    color: #fff;
    font-size: 0.3rem;
    /* animation: fadeInUp; */
    /* animation-duration: 3s; */
    /* @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1500px) {
      font-size: 4rem;
    } */
  }

  .subtitulo {
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    text-align: center;
    /* animation: fadeInUp; */
    /* animation-duration: 3s; */
    /* @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    } */
  }
  /* @media (max-width: 800px) {
    width: 90vw;
  } */
  .listas {
    color: #fff;
    margin-left: 1vw;
    /* animation: fadeIn; */
    /* animation-duration: 2s; */
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
  .tituloDin {
    color: #fff;
    font-size: 400%;
    animation: fadeIn;
    animation-duration: 2s;
  }
`
