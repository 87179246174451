import * as palette from "../../utils/styledVariables";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const styles = {
  titleCaixa: {
    padding: "15px",
    // height: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // justifyItems: 'center',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    textAlign: "left",
    color: palette.profileWhiteShade,
    background: "linear-gradient(123.21deg, #D56D0D 0.85%, #1609B3 207.57%)",
  },
  title:{
    color: palette.profileWhiteShade,
    marginBottom: 0,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
  },
  subtitle:{
    color: palette.profileWhiteShade,
    marginBottom: 0,
    fontWeight: 200,
    fontSize: "0.6em",
    fontStyle: "italic",
  },
  card1: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "40px",
    padding: "auto",
    height: "150px",
    color: palette.preto,
    fontStyle: "normal",
    fontWeight: 500,
    background: "rgba(212, 109, 13, 0.1)",
    textAlign: "center",
  },
  card2: {
    display: "flex",
    padding: "auto",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "70px",
    height: "308px",
    color: palette.preto,
    fontStyle: "normal",
    fontWeight: 500,
    background: "rgba(212, 109, 13, 0.1)",
    textAlign: "center",
  },
  card4: {
    padding: "16px",
    height: "308px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: palette.preto,
    fontStyle: "normal",
    fontWeight: 450,
    background: "rgba(212, 109, 13, 0.1)",
    textAlign: "left",
  },
  textContent: {
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  subtext: {
    fontSize: "20px",
    fontStyle: "italic",
    color: palette.preto,
    fontWeight: 100,
    textAlign: "center",
    marginTop: "-10px",
  },
};

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: 'theme.shadows[1]',
    fontSize: 11,
  },
}));

