import React, { useEffect } from 'react';

import {Modal,Button,Divider,Alert,Snackbar } from '@mui/material';
import { Container,ContainerConfirm } from './styles';
import SessionSelecionar from './SessionSelecionar';
import SessionRescisao from './SessionRescisao';
import SessionConfirm from './SessionConfirm';
import logo from '../../../assets/LOGO_OXETECH_WORK.svg'
import api from '../../../services/api'


function ModalContratacao(props){
    
    const {open, close, candidates, job  } = props
    const [selectedCandidates, setSelectedCandidates] = React.useState([])
    const [openConfirm, setOpenConfirm] = React.useState('')
    const [endDate, setEndDate] = React.useState('')
    const [openAlert, setOpenAlert] = React.useState('')
    const [contratos, setContratos] = React.useState({
        data_encerramento: '',
        candidatura_id: 0
    })
    
    const [botaoSelecionar, setBotaoSelecionar] = React.useState(false)

    const rescisaoRef = React.useRef(null);
    const confirmRef = React.useRef(null);

    const handleClickRescisao = () => {
        confirmRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });

    }
      
      const styles =
    {
        botao:{
            background: '#741F8A',
            '&:hover': {
                background:"rgba(116, 31, 138, 0.6)"
            } ,
        }
    }
    useEffect(() => {
        if (botaoSelecionar) {
          handleClickRescisao();
       
        }
      }, [botaoSelecionar, rescisaoRef]);

    const handleButtonNextSessionSelecionar = (candidate) => {
        setSelectedCandidates(candidate)        
    }
    const handleButtonSelec = () => {
        setBotaoSelecionar(true)

    }
    const handleButtonNextSessionRescisao = (date) => {
        setEndDate(date)        
    }
   
    const handleButtonNextSessionConfirm = async () => {
            const data = []
          
            for (const candidate of selectedCandidates) {
                const contratos = {
                    data_encerramento: endDate,
                    candidatura_id: candidates[candidate].id
                }
                data.push(contratos)
            }
            const jsonData = {data: JSON.stringify(data)}
            // console.log(jsonData)
            const response = await api.post("/contratacao/create/byList", jsonData).then(resp => {
                close()
                setOpenConfirm(true)
            }).catch(err => {
                setOpenAlert(err.response.data.error)
                // alert(err.error.message)
            })

    }
    const ModalConfirm = () => {
    return(
        <Modal
        open={openConfirm}
        onClose={() => {setOpenConfirm(false); window.location.reload()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow: 'scroll'}}
        >
            <ContainerConfirm style={{width: '40%', height: 'auto'}}>
            <div className='content' >
                <div className='intro' >
                    <img src={logo} alt='LOGO_OXETECH_WORK' width='40%' />
                    <h3>
                        "Sua contratação foi submetida a avaliação da SECTI. Em breve, você receberá o feedback por email."
                    </h3>
                </div>
            </div>
            </ContainerConfirm>
        </Modal>
    )
    }
   
    return(
        <div>

        <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow: 'scroll', marginTop: "2%"}}
        >
            <Container >
                <div className='content' >

                    <div className='intro' >
                        <img src={logo} alt='LOGO_OXETECH_WORK' width='25%' />
                        <h2>
                            Contratação para: <span style={{color: '#8E24AA'}} >{job?.titulo}</span>
                        </h2>
                    </div>
                    <SessionSelecionar candidates={candidates} quantidade={job.qtd_em_oferta} onNext={handleButtonNextSessionSelecionar} button={handleButtonSelec} />
                    <div style={{display:endDate?"none":'flex', justifyContent: 'flex-end', marginTop: '3%'}} >
                        <Button disabled={selectedCandidates.length ===0} variant="contained" sx={styles.botao} onClick={handleButtonSelec} >Continuar</Button>
                    </div>
                    <div style={{display:botaoSelecionar?"block":'none'}} ref={confirmRef }>
                        <Divider variant="middle" sx={{m: "2% 0"}} />
                        <SessionRescisao  onNext={handleButtonNextSessionRescisao} />
                        <Divider variant="middle" sx={{m: "2% 0"}} />
                        <SessionConfirm selectedCandidates={selectedCandidates} candidates={candidates} titulo={job.titulo}  />
                        <div style={{display:'flex', justifyContent: 'flex-end', marginTop: '3%'}} >
                            <Button variant="contained" disabled={!endDate} sx={styles.botao} onClick={handleButtonNextSessionConfirm} >Contratar</Button>
                        </div>
                    </div>
                </div>

            </Container>
        </Modal>    
        <ModalConfirm/>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={()=> setOpenAlert('')}>
        <Alert onClose={()=> setOpenAlert('')} variant="filled" severity="error" sx={{ width: '100%' }}>
          {openAlert}
        </Alert>
      </Snackbar>
        </div>
    )
}


export default ModalContratacao