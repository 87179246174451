import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme(); 

export const useStyles = makeStyles({

  bodyStyles: {
    margin: 0,
    padding: 0,
  },
  
    courseNameStyle: {
        fontWeight: 'bold',
        fontSize: '40',
        marginLeft: '20px',
        fontFamily: 'Poppins',
      },
      courseStyle: {
        flexDirection: 'column',
        flexGrow: 1,
       
        fontFamily: 'Poppins',
        textAlign: 'center',
        fontColor: 'white'
      },

  whiteText: {
        color: 'white',
        fontWeight: 'italic', 
        fontSize: '18', 
        
  },
  
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  informationText: {
    marginBottom: '10px',
    marginLeft: '18px'
  },
  
});