import React, { useState } from "react";
import {
    Modal,
    Typography,
    styled,
    Box,
    Button,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from '@mui/icons-material/Reply';
import * as palette from "../../utils/styledVariables"



const SimpleButton = styled((props) => <Button {...props} />)(() => ({
    background:
        palette.laranjaSecundario,
    color: palette.profileWhiteShade,
    margin: "10px 5px 0 5px",
    height: "35px",
    "&:hover": {
        backgroundColor: palette.laranjaIntermediario2 ,
      }
}));


const styleModal = {

    width: "300px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: 'center'

};


function ModalConfirmacao(props) {


    return (
        <Modal open={props.open}>

            <Box sx={styleModal}>



                <Typography sx={{ color: palette.preto }}>
                    { props.message ? props.message : "Confirma a alteração no status?" }
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SimpleButton onClick={props.handleChange} startIcon={<SaveIcon />}> SIM </SimpleButton>
                    <SimpleButton onClick={props.handleRevert} startIcon={<ReplyIcon />}> NÃO </SimpleButton>
                </Box>
            </Box >
        </Modal >)
}

export default ModalConfirmacao;