
import { Typography, TextField, Grid, Paper } from "@mui/material";
import React from "react";

const style = {
    paper: {
        padding: "10px",
        width: "100%",
        margin: "5px"
    },
    field: { padding: "5px" }

};


function DadosVagas(props) {

    return (<Grid item container xs={12} sm={12} md={6} lg={6}>
        <Paper sx={style.paper}>
            <Typography>{props.title}</Typography>
            <Grid item container xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={style.field}>
                   Total
                    <TextField
                        hiddenLabel
                        id="qtd-vagas"
                        variant="filled"
                        type="number"
                        fullWidth
                        value={props.total}
                        onChange={props.handleTotal}
                        onBlur={props.handleTotal}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={style.field}>
                    Em oferta
                    <TextField
                        hiddenLabel
                        id="qtd-vagas"
                        variant="filled"
                        type="number"
                        fullWidth
                        value={props.oferta}
                        onChange={props.handleOferta}
                        onBlur={props.handleOferta}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={style.field}>
                    Preenchidas
                    <TextField
                        hiddenLabel
                        id="qtd-vagas"
                        variant="filled"
                        type="number"
                        fullWidth
                        value={props.preenchidas}
                        onChange={props.handlePreenchidas}
                        onBlur={props.handlePreenchidas}
                    />
                </Grid>
            </Grid>
        </Paper>
        </Grid>)
}

export default DadosVagas