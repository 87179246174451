import React, {useState} from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Snackbar,Alert
} from "@mui/material";
import logo from "../../../assets/homeTriangle/oxeTechLabLogoON.png";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../../services/api";
function ModalMatricula({ open, handleClose, selecionados, idTurma }) {
  // console.log(selecionados)
  const [loading, setLoading] = useState(false);
  const [matriculaSucesso, setMatriculaSucesso] = useState('');
  const [matriculaError, setMatriculaError] = useState('');
  
  const handleMatricula = () => {
    setLoading(true);
    const selecionadosId = selecionados.map(selec => selec.inscricao)
    api.post(`/matriculas/turma/${idTurma}`, selecionadosId).then(response => {
        setLoading(false);
        setMatriculaSucesso('Alunos matriculados com sucesso!')        
        setTimeout(() => 
            handleClose()
            ,[2000])
            window.location.reload()
    }).catch(err =>{
        setMatriculaError(err.response.data.error); 
        console.log(err.response.data)
    })
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5%",
        }}
      >
        <img src={logo} alt="logoOxetechLab" width="30%" />
        <h3 style={{ textAlign: "center", fontSize: "1.3em" }}>
          Confirmar matrícula de todos os inscritos da lista de pré-seleção?
        </h3>
        <h4 style={{ fontWeight: "lighter", textAlign: "center" }}>
          Ao confirmar, todos que estão pré-selecionados serão{" "}
          <strong>matriculados</strong> na turma e os demais estarão na situação
          de <strong>"em espera"</strong> e poderão ser matriculados até o 1°
          dia útil de aula.
        </h4>
        <h5 style={{ fontWeight: "12px", textAlign: "center" }}>
          Os inscritos receberão e-mail do resultado da seleção e a turma irá para a aba de "Iniciadas".
        </h5>
        <DialogActions>
          <Button
            onClick={handleMatricula}
            startIcon={
                loading ? <CircularProgress color="inherit" size={20} /> : null
              }
            variant="contained"
            sx={{
              backgroundColor: "#D46D0D",
              
              color: "white",
              "&:hover": { backgroundColor: "rgba(212, 109, 13, 0.45)" },
              marginRight: '10%'
            }}
          >
            Confirmar
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
                borderColor:'#D46D0D',
              color: "#D46D0D",
              "&:hover": { borderColor: "rgba(212, 109, 13, 0.45)" },
            }}
          >
            Fechar
          </Button>
        </DialogActions>
      </div>
      <Snackbar
              open={matriculaSucesso.length > 0}
              autoHideDuration={6000}
              onClose={() => setMatriculaSucesso("")}
            >
              <Alert
                variant="filled"
                onClose={() => setMatriculaSucesso("")}
                severity="success"
                sx={{ width: "100%" }}
              >
                {matriculaSucesso}
              </Alert>
            </Snackbar>
            <Snackbar
              open={matriculaError.length > 0}
              autoHideDuration={6000}
              onClose={() => setMatriculaError("")}
            >
              <Alert
                variant="filled"
                onClose={() => setMatriculaError("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {matriculaError}
              </Alert>
            </Snackbar>
    </Dialog>
  );
}

export default ModalMatricula;
