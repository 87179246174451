import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'

export const Caixa = styled(Box)(({ theme }) => ({
  // backgroundColor: 'inherit',
  // boxShadow: 'none',
  // verticalAlign: 'middle',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // margin: theme.spacing(1),
  // textAlign: 'left',
  // color: theme.palette.text.secondary,
  '.content': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    }
  }
}))
