import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import SkillCard from './SkillCard'
import LanguageCard from './LanguageCard'
import SobreMimCard from './SobreMimCard'
import FormationCard from './FormationCard'
import ExperienceCard from './ExperienceCard'
import CourseCard from './CourseCard'

function ProfileAluno() {
    return (
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 0 }}
        >
            <Grid container rowSpacing={2} spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                    <SobreMimCard />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <SkillCard />
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <FormationCard />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <LanguageCard />
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <ExperienceCard />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <CourseCard />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProfileAluno;